import CoinGecko from 'coingecko-api';
import {TokenId} from "../../../../types";
import {getToken} from "../../../../utilities";
import { NetworkState } from "../../../../types";

export interface GetPriceOfInput {
  tokenId: TokenId;
  network: NetworkState;
}

export type GetPriceOfOutput = {
  price: number;
};

const getPriceOf = async ({ tokenId, network }: GetPriceOfInput): Promise<GetPriceOfOutput> => {
  const CoinGeckoClient = new CoinGecko();
  const resp = await CoinGeckoClient.simple.price({
    ids: [getToken(tokenId, network).coingeckoId],
    vs_currencies: ['usd'],
  });
  return {
    price: resp.data.thrupenny.usd,
  };
};

export default getPriceOf;
