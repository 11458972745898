import {css} from '@emotion/react';
import {useTheme} from '@mui/material';

export const useStyles = () => {
    const theme = useTheme();

    return {
        drawer: css`
          display: flex;
          justify-content: space-between;
          align-items: center;
        `,
        closeIcon: css`
          margin-top: 5px;
          height: ${theme.shape.iconSize.xLarge}px;
          width: ${theme.shape.iconSize.xLarge}px;
          margin-left: auto;
          min-width: 0;
          padding: 0;
          background-color: transparent;
        `,SelectNetworkButton: css`
        margin-top: 5px;
        height: ${theme.shape.iconSize.xLarge}px;
        width: ${theme.shape.iconSize.xLarge}px;
        margin-left: auto;
        min-width: 0;
        padding: 0;
        background-color: transparent;
        `,
        switches: css`
          display: flex;
          justify-content: space-between; 
          margin-top: 16px;
          align-items: center;
        `,
        drawerText: css`
          color: ${theme.palette.text.primary};
          font-size: 12px;
        `,
        products: css`
          color: ${theme.palette.text.primary};
        `,
    };
};
