/** @jsxImportSource @emotion/react */
import { Button, Modal as MUIModal, ModalProps as MUIModalProps, useTheme } from '@mui/material';
import Fade from '@mui/material/Fade';
import React, { ReactElement } from 'react';
import { useModalStyles } from './styles';
import CloseIcon from "@mui/icons-material/Close";

export interface ModalProps extends Omit<MUIModalProps, 'title' | 'open'> {
  className?: string;
  isOpen: boolean;
  handleClose: () => void;
  title?: string | ReactElement | ReactElement[];
  noHorizontalPadding?: boolean;
}

export const Modal: React.FC<ModalProps> = ({
  className,
  children,
  handleClose,
  isOpen,
  title,
  noHorizontalPadding,
  ...otherModalProps
}) => {
  const s = useModalStyles({ hasTitleComponent: Boolean(title), noHorizontalPadding });
  const theme = useTheme();

  return (
    <MUIModal
      open={isOpen}
      onClose={handleClose}
      closeAfterTransition
      {...otherModalProps}
    >
      <Fade in={isOpen}>
        <div css={s.box} className={className}>
          <div css={s.modalBackground}>
            <div css={s.titleWrapper}>
              <div css={s.titleComponent}>{title}</div>
              <Button css={s.closeIcon} disableRipple onClick={handleClose}>
                <CloseIcon htmlColor={theme.palette.customBackground.icon}/>
              </Button>
            </div>
            <div css={s.contentWrapper}>{children}</div>
          </div>
        </div>
      </Fade>
    </MUIModal>
  );
};
