import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = (account?: { account: string | undefined }) => {
  const theme = useTheme();

  return {
    referralBodyText: css`
      font-size: 14px;
      color: ${theme.palette.text.primary};

      ${theme.breakpoints.down('xl')} {
        font-size: 16px;
      }

      ${theme.breakpoints.down('md')} {
        font-size: 14px;
        margin-bottom: ${account ? '14px' : ''}
      }
    `,
    sectionTitle: css`
      font-size: 22px;
      //font-size: 28px;
      font-weight: 600;
      color: ${theme.palette.text.primary};

      ${theme.breakpoints.down('xl')} {
        font-size: 22px;
      }

      ${theme.breakpoints.down('md')} {
        font-size: 18px;
      }
    `,
    sectionBodyTexts: css`
      font-size: 14px;
      font-weight: 500;
      color: ${theme.palette.text.secondary};

      ${theme.breakpoints.down('xl')} {
        font-size: 16px;
      }

      ${theme.breakpoints.down('md')} {
        font-size: 14px;
      }
    `,
    sectionBodyValues: css`
      //font-size: 24px;
      font-size: 19px;
      color: ${theme.palette.text.primary};

      ${theme.breakpoints.down('xl')} {
        font-size: 20px;
      }

      ${theme.breakpoints.down('md')} {
        font-size: 16px;
      }
    `,
    sectionBodyRewards: css`
      //font-size: 24px;
      font-size: 19px;
      font-weight: 500;
      color: ${theme.palette.customText.sometimes};

      ${theme.breakpoints.down('xl')} {
        font-size: 20px;
      }

      ${theme.breakpoints.down('md')} {
        font-size: 16px;
      }
    `,
    sectionTitleText: css`
      ${theme.breakpoints.down('md')} {
        font-size: ${theme.typography.h4.fontSize};
        font-weight: ${theme.typography.h4.fontWeight};
      }
    `,
    sectionBody: css`
      ${theme.breakpoints.down('md')} {
        margin-bottom: ${theme.spacing(6)};
      }
    `,
    cards: css`
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 16px;
    `,
    cardsWrapper: css`
      display: flex;
      justify-content: space-between;
      gap: 24px;
      ${theme.breakpoints.down('xl')} {
        flex-direction: column;
      }
    `,
    poolTitle: css`
      font-size: 32px;
      color: ${theme.palette.text.primary};
      ${theme.breakpoints.down('xl')} {
        font-size: 25px;
      }

      ${theme.breakpoints.down('md')} {
        font-size: 18px;
      }
    `,
    pools: css`
      margin-top: 13px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      ${theme.breakpoints.down('md')} {
        margin-top: 20px;
        margin-bottom: 24px;
      }
    `,
  };
};
