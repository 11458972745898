import { MutationObserverOptions, useMutation } from 'react-query';

import FunctionKey from 'constants/functionKey';

import withdrawToken, {WithdrawTokenOutput, WithdrawInput} from "./index";
import {useStakeContract} from "../../../contracts";
import queryClient from "../../queryClient";
import {POOLS} from "../../../../constants/pools";
import { TPYChainId } from 'types';

const useWithdrawToken = (
    { accountAddress, chainId }: { accountAddress: string; chainId: TPYChainId },
    options?: MutationObserverOptions<
        WithdrawTokenOutput,
        Error,
        Omit<WithdrawInput, 'stakingContract'>
        >,
) => {
  const stakingContract = useStakeContract(chainId);

  return useMutation(
      [FunctionKey.WITHDRAW_TOKEN, 'withdrawContract' ],
      params =>
          withdrawToken({
              stakingContract,
            ...params
          }),
      {
        ...options,
        onSuccess: (...onSuccessParams) => {
            queryClient.invalidateQueries([FunctionKey.GET_USER_INFO, `${POOLS.join(',')} ${accountAddress}`]);
            if (options?.onSuccess) {
            options.onSuccess(...onSuccessParams);
          }
        },
      },
  );
};

export default useWithdrawToken;
