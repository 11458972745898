/** @jsxImportSource @emotion/react */
import React from 'react';
import {useTranslation} from 'translation';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import {useStyles} from './styles';
import {Box, Tooltip, Typography} from "@mui/material";
import {Line} from "../../../../components/Line";

const PoolHeader: React.FC<{title: string}> = ({title}) => {
    const {t} = useTranslation();
    const styles = useStyles();

    return (
        <>
            <Box css={styles.poolHeader}>
                <Typography css={styles.poolTitle}>
                    {t(`layout.stake.pools.${title}`)}
                </Typography>
                <Box css={styles.columns}>
                    <Box component={'div'}>
                        <Typography css={styles.columnsPoolTitle}>
                            {t('layout.stake.pools.pools')}
                        </Typography>
                    </Box>
                    <Box component={'div'} sx={{display: 'flex', justifyContent: 'space-evenly', width: '100%'}}>
                        <Typography component={'div'} css={styles.columnsTitle}>
                            {t('layout.stake.pools.reward')}
                            <Tooltip title={t('info.apyReward')} placement="top" arrow>
                                <InfoOutlinedIcon fontSize={'small'}/>
                            </Tooltip>
                        </Typography>
                        <Typography component={'div'} css={styles.columnsTitle}>
                            {t('layout.stake.pools.lockTerm')}
                            <Tooltip title={t('info.lockTerm')} placement="top" arrow>
                                <InfoOutlinedIcon fontSize={'small'}/>
                            </Tooltip>
                        </Typography>
                        <Typography component={'div'} css={styles.columnsTitle}>
                            {t('layout.stake.pools.totalStaked')}
                            <Tooltip title={t('info.totalStaked')} placement="top" arrow>
                                <InfoOutlinedIcon fontSize={'small'}/>
                            </Tooltip>
                        </Typography>
                        <Typography component={'div'} css={styles.columnsTitle}>
                            {t('layout.stake.pools.yourStake')}
                            <Tooltip title={t('info.yourStake')} placement="top" arrow>
                                <InfoOutlinedIcon fontSize={'small'}/>
                            </Tooltip>
                        </Typography>
                    </Box>
                    <Box component={'div'} sx={{minWidth: '240px'}}/>
                </Box>
                <Line/>
            </Box>
        </>
    );
};

export default PoolHeader;
