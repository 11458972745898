import {InjectedConnector} from '@web3-react/injected-connector';
import {WalletConnectConnector} from '@web3-react/walletconnect-connector';
import {WalletLinkConnector} from '@web3-react/walletlink-connector';
import {FortmaticConnector} from '@web3-react/fortmatic-connector';
import {InfinityWalletConnector} from '@infinitywallet/infinity-connector';

import config from 'config';
import {TPYChainId} from 'types';

import {Connector} from './types';

export const injectedConnector = new InjectedConnector({ supportedChainIds: [TPYChainId.MAINNET, TPYChainId.ARBITRUM_ONE] });

const walletConnectConnector = new WalletConnectConnector({
  rpc: { [TPYChainId.MAINNET]: config.rpcUrl },
  chainId: TPYChainId.MAINNET,
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true,
});

const coinbaseWalletConnector = new WalletLinkConnector({
  url: config.rpcUrl,
  appName: 'Thrupenny',
});

const infinityWalletConnector = new InfinityWalletConnector({
  supportedChainIds: [TPYChainId.MAINNET, TPYChainId.ARBITRUM_ONE],
});

const fortmaticConnector = new FortmaticConnector({
  apiKey: config.fortmaticApiKey,
  chainId: Number(1),
});

export const connectorsByName = {
  [Connector.MetaMask]: injectedConnector,
  [Connector.WalletConnect]: walletConnectConnector,
  [Connector.CoinbaseWallet]: coinbaseWalletConnector,
  [Connector.Fortmatic]: fortmaticConnector,
  [Connector.TrustWallet]: injectedConnector,
  [Connector.OperaWallet]: injectedConnector,
  [Connector.InfinityWallet]: infinityWalletConnector,

};
