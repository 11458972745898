
import { TPYChainId } from 'types';

export const API_ENDPOINT_URLS = {
    [TPYChainId.MAINNET]: 'https://goerli.etherscan.io/',
    [TPYChainId.TESTNET]: 'https://goerli-rollup.arbitrum.io/rpc',
    [TPYChainId.ARBITRUM_ONE]: 'https://arb1.arbitrum.io/rpc',
    [TPYChainId.ARBITRUM_GOERLI]: 'https://goerli-rollup.arbitrum.io/rpc'
};
export const TX_URLS = {
    [TPYChainId.MAINNET]: 'https://etherscan.io/',
    [TPYChainId.TESTNET]: 'https://goerli.etherscan.io/',
    [TPYChainId.ARBITRUM_ONE]: 'https://arbiscan.io/',
    [TPYChainId.ARBITRUM_GOERLI]: 'https://goerli-rollup-explorer.arbitrum.io/'

};
export const RPC_URLS: {
    [key: string]: string[];
} = {
    [TPYChainId.MAINNET]: ['https://eth-mainnet.g.alchemy.com/v2/gU9NwU6R2leMp9THFmEJzPnAE2ic9mI3'],
    [TPYChainId.TESTNET]: ['https://goerli.blockpi.network/v1/rpc/public'],
    [TPYChainId.ARBITRUM_ONE]: ['https://arb-mainnet.g.alchemy.com/v2/R16sXNr7a779Ji5fZTqkkqA-0gjMS0O5'],
    [TPYChainId.ARBITRUM_GOERLI]:['https://endpoints.omniatech.io/v1/arbitrum/goerli/public']

};
