/** @jsxImportSource @emotion/react */
import React from 'react';

import {useTranslation} from 'translation';

import { useStyles } from './styles';
import {Box, Typography} from "@mui/material";
import Icon from'../../../../assets/img/icons/thrupennyIcon.svg';


export const PoolIcon: React.FC = () => {
    const {t} = useTranslation();
    const styles = useStyles();

    return <Box sx={{minWidth: {lg: 190, xs: '100%'}}}>
        <Box css={styles.pool}>
            <Box><img src={Icon} alt="..." width={25} height={25} /></Box>
            <Box sx={{display: 'flex', flexDirection: 'column'}}>
                <div>
                    <Typography css={styles.tpy}>TPY</Typography>
                </div>
                <div>
                    <Typography css={styles.earn}>{t('layout.stake.pools.stakeEarn')}</Typography>
                </div>
            </Box>
        </Box>
    </Box>;
};
