import { t } from 'translation';

import { ReactComponent as MetaMaskLogo } from '../../assets/img/wallets/metaMaskLogo.svg';
import { ReactComponent as FortmaticLogo } from '../../assets/img/wallets/fortmaticLogo.svg';
import { ReactComponent as OperaWalletLogo } from '../../assets/img/wallets/operaWalletLogo.svg';
import { ReactComponent as WalletConnectLogo } from '../../assets/img/wallets/walletConnectLogo.svg';
import { ReactComponent as TrustWalletLogo } from '../../assets/img/wallets/trustWalletLogo.svg';
import { ReactComponent as CoinbaseWalletLogo } from '../../assets/img/wallets/coinbase.svg';
import { Connector } from 'clients/web3';
import { isRunningInOperaBrowser } from 'clients/web3/walletDetectionUtils';

import { BaseWallet, Wallet } from './types';

export const WALLETS: Wallet[] = [
  {
    name: t('wallets.metamask'),
    Logo: MetaMaskLogo,
    connector: Connector.MetaMask,
  },
  {
    name: t('wallets.trustWallet'),
    Logo: TrustWalletLogo,
    connector: Connector.TrustWallet,
  },
  {
    name: t('wallets.walletConnect'),
    Logo: WalletConnectLogo,
    connector: Connector.WalletConnect,
  },
  {
    name: t('wallets.coinbaseWallet'),
    Logo: CoinbaseWalletLogo,
    connector: Connector.CoinbaseWallet,
  },
  {
    name: t('wallets.fortmatic'),
    Logo: FortmaticLogo,
    connector: Connector.Fortmatic,
  },
];

// Add Opera Wallet to the top of the list if dApp is running in Opera
if (isRunningInOperaBrowser()) {
  WALLETS.unshift({
    name: t('wallets.opera'),
    Logo: OperaWalletLogo,
    connector: Connector.OperaWallet,
    mainnetOnly: true,
  });
}
export const UPCOMING_WALLETS: BaseWallet[] = [];
