import {NetworkState, TPYChainId} from "../../types";
import sample from "lodash/sample";
import {API_ENDPOINT_URLS, RPC_URLS} from "../../constants/endpoints";
import {ETH_SCAN_URLS} from "../../constants/eth";

export interface ConfigState {
    chainId: TPYChainId;
    isOnTestnet: boolean;
    rpcUrl: string;
    apiUrl: string;
    ethScanUrl: string;
    fortmaticApiKey: string;
    network: NetworkState.ETH | NetworkState.ARBITRUM;

}

export const initialConfigState: ConfigState = {
    chainId: parseInt(localStorage.getItem('network') || NetworkState.ETH) as TPYChainId,
    isOnTestnet: !!(process.env.REACT_APP_IS_TESTNET),
    rpcUrl: sample(RPC_URLS[parseInt(localStorage.getItem('network') || NetworkState.ETH)]) as string,
    apiUrl: API_ENDPOINT_URLS[parseInt(localStorage.getItem('network') || NetworkState.ETH) as TPYChainId],
    ethScanUrl: ETH_SCAN_URLS[parseInt(localStorage.getItem('network') || NetworkState.ETH) as TPYChainId],
    fortmaticApiKey: process.env.REACT_APP_FORTMATIC_API_KEY || ' ',
    network: localStorage.getItem('network') === NetworkState.ARBITRUM
            ? NetworkState.ARBITRUM
            : NetworkState.ETH,
};
