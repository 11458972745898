import { TPYChainId } from 'types';
import { Staking } from 'types/contracts';

export interface GetAddressToldInput {
  stakeContract: Staking;
  accountAddress: string;
  chainId: TPYChainId;
}
export type GetAddressToldOutput = {
  userId: string;
};

const getAddressTold = async ({
  stakeContract,
  accountAddress
}: GetAddressToldInput): Promise<GetAddressToldOutput> => {
    const resP = await stakeContract.methods.addressToId(accountAddress).call();
    return {
        userId: resP.toString()
    };
};

export default getAddressTold;
