import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {initialConfigState} from './states';
import { RootState } from '../index';
import {API_ENDPOINT_URLS, RPC_URLS} from "../constants/endpoints";
import {NetworkState, TPYChainId} from "../types";
import {ETH_SCAN_URLS} from "../constants/eth";
import sample from "lodash/sample";

export const configSlice = createSlice({
    name: 'config',
    initialState: initialConfigState,
    reducers: {
        changeConfig: (state, action: PayloadAction<{ network: NetworkState }>) => {
            state.network = action.payload.network;
            state.chainId = parseInt(action.payload.network);
            state.rpcUrl = sample(RPC_URLS[parseInt(localStorage.getItem('network') || NetworkState.ETH)]) as string;
            state.apiUrl = API_ENDPOINT_URLS[parseInt(localStorage.getItem('network') || NetworkState.ETH) as TPYChainId];
            state.ethScanUrl = ETH_SCAN_URLS[parseInt(localStorage.getItem('network') || NetworkState.ETH) as TPYChainId];
        }
    },
});

export const { changeConfig } = configSlice.actions;

export const getConfig = (state: RootState) => state.config;

export default configSlice.reducer;
