import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  return {
    referralContainer: css`
      width: 100%;
      padding: 13px 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: ${theme.palette.customBackground.navBar};
      box-shadow: 2px 12px 32px -12px rgba(14, 114, 102, 0.06);
      border-radius: 12px;
    `,
    icons: css`
      display: flex;
      gap: 24px;
      align-items: center;
      a {
        height: 18px;
      }
    `,
    icon: css`
      & > path {
        fill: ${theme.palette.text.primary};
      }
    `,
    link: css`
      color: ${theme.palette.text.primary};
      font-size: 13px;
    `,
  };
};
