/** @jsxImportSource @emotion/react */
import * as React from 'react';
import { useDispatch } from 'react-redux';
import {NetworkState, TPYChainId} from "../../../types"
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useSelector } from 'react-redux';
import {changeConfig, getConfig} from "../../../features";
import switchNetwork from "../../../clients/web3/useAuth/switchNetwork";
import {ReactComponent as ArbitrumIcon} from '../../../assets/img/icons/arbitrumIcon.svg';
import {ReactComponent as EthereumIcon} from '../../../assets/img/icons/ethereumIcon.svg';
import {useStyles} from "./styles";
import setUpNetwork from "../../../clients/web3/useAuth/setUpNetwork";

export default function SelectSmall() {
    const dispatch = useDispatch();
    const {network} = useSelector(getConfig);
    const styles = useStyles();

    const handleChange = async (event:SelectChangeEvent<NetworkState>) => {
        try {
            await switchNetwork(+event.target.value as TPYChainId);
            localStorage.setItem('network', event.target.value);
            await dispatch(changeConfig({ network: event.target.value as NetworkState }));
        }catch (e: unknown){
            const message = e as string;
            if (message.includes('wallet_addEthereumChain')){
                await setUpNetwork(+event.target.value as TPYChainId);
            }
        }
    };

    return (
        <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
            <Select
                value={network}
                onChange={handleChange}
                css={styles.select}
            >
                <MenuItem value={NetworkState.ETH} sx={{gap: '10px'}}><EthereumIcon/> <span>Ethereum</span></MenuItem>
                <MenuItem value={NetworkState.ARBITRUM} sx={{gap: '10px'}}><ArbitrumIcon/> <span>Arbitrum</span></MenuItem>
            </Select>
        </FormControl>
    );
}
