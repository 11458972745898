import {PoolType} from "../../types";

export const pools: PoolType[] = [
  {
    "poolId": 0,
    "percent": 10,
    "term": 0,
    "total": '0',
    "accountBalance": '0',
    "disabled": true
  },
  {
    "poolId": 1,
    "percent": 20,
    "term": 30,
    "total": '0',
    "accountBalance": '0',
    "disabled": true
  },
  {
    "poolId": 2,
    "percent": 30,
    "term": 90,
    "total": '0',
    "accountBalance": '0',
    "disabled": true
  },
  {
    "poolId": 3,
    "percent": 50,
    "term": 180,
    "total": '0',
    "accountBalance": '0',
    "disabled": true
  },
  {
    "poolId": 6,
    "percent": 65,
    "term": 270,
    "total": '0',
    "accountBalance": '0',
    "disabled": true
  },
  {
    "poolId": 7,
    "percent": 75,
    "term": 365,
    "total": '0',
    "accountBalance": '0',
    "disabled": true
  },
  {
    "poolId": 4,
    "percent": 100,
    "term": 365,
    "total": '0',
    "accountBalance": '0',
    "disabled": true
  }
]
