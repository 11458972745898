import { QueryObserverOptions, useQuery } from 'react-query';

import { useStakeContract } from 'clients/contracts/hooks';
import FunctionKey from 'constants/functionKey';
import {GetUserInfoPerPoolInput, GetUserInfoPerPoolOutput} from "./index";
import getUserInfo from "./index";

type Options = QueryObserverOptions<
  GetUserInfoPerPoolOutput,
  Error,
  GetUserInfoPerPoolOutput,
  GetUserInfoPerPoolOutput,
  [FunctionKey.GET_USER_INFO, string]
>;

const useGetUserInfoPerPool = (
  { poolIds, accountAddress, network, chainId }: Omit<GetUserInfoPerPoolInput, 'stakeContract'>,
  options?: Options,
) => {
  const stakeContract = useStakeContract(chainId);
  return useQuery(
    [FunctionKey.GET_USER_INFO, `${poolIds.join(',')} ${accountAddress} ${network}`],
    () => getUserInfo({ stakeContract, accountAddress ,poolIds, network, chainId }),
    options,
  );
};

export default useGetUserInfoPerPool;
