import { Staking } from 'types/contracts';
import BigNumber from "bignumber.js";
import convertWeiToTokens from "../../../../utilities/convertWeiToTokens";
import { NetworkState, TPYChainId } from "../../../../types";

export interface GetUserInfoPerPoolInput {
  stakeContract: Staking;
  accountAddress: string;
  chainId: TPYChainId;
  poolIds: number[];
    network: NetworkState;
}

export interface UserInfo{
  poolId: number;
  stake: BigNumber;
  reward: BigNumber;
  startDate: string;
  endDate: string;
    network: NetworkState;
}

export type GetUserInfoPerPoolOutput = {
  userInfo: { [p: number]: UserInfo };
  stakes: number;
  reward: number;
  network: NetworkState;
};

const getUserInfo = async ({
  stakeContract,
  accountAddress,
  poolIds,
  network
}: GetUserInfoPerPoolInput): Promise<GetUserInfoPerPoolOutput> => {
  const res: { [poolId:number]: UserInfo } = {};
  let stakes = 0;
  let reward = 0;
    for (const poolId of poolIds) {
        const resUStakes = await stakeContract.methods.stakes(poolId, accountAddress).call();
        let resURewards = '0';
        try {
            resURewards = await stakeContract.methods.stakeOfAuto(poolId, accountAddress).call();
        }catch (e) {
            console.log(e);
        }
        stakes += +convertWeiToTokens({valueWei: new BigNumber(resUStakes.amount), tokenId: 'tpy', network });
        reward += +convertWeiToTokens({valueWei: new BigNumber(resURewards === '0' ? resUStakes.amount : resURewards), tokenId: 'tpy' , network});
        res[poolId] = {
            poolId: poolId,
            network : network,
            stake: new BigNumber(resUStakes.amount),
            reward: new BigNumber(resURewards === '0' ? resUStakes.amount : resURewards),
            startDate: resUStakes.checkpoint,
            endDate: resUStakes.releaseCheckpoint
          }
  }
  return {
      userInfo: res,
      network : network,
      stakes: stakes,
      reward: reward
  };
};

export default getUserInfo;
