/** @jsxImportSource @emotion/react */
import React from 'react';

import { useStyles } from './styles';
import { Card } from "@mui/material";

export interface CardType {
    children?: React.ReactNode;
    variant?: "small" | "normal" | "modal";
}

export const MaterialUICard: React.FC<CardType> = ({ children, variant = "normal" }) => {
  const styles = useStyles({ variant });

  return (
          <Card css={styles.card}>
              {children}
          </Card>
  );
};

export const CardComponent = (props: CardType) => <MaterialUICard variant={props.variant} {...props} />;
