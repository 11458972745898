export interface StakeModalState {
    isOpen: boolean;
    percent: number;
    date: number;
    type: string;
    poolId: number;
    userStaked?: string;
    startDate?: string;
    stakeDisabled?: boolean;
}

export const initialStakeModalState: StakeModalState = {
    isOpen: false,
    percent: 0,
    date: 0,
    type: '',
    poolId: 0,
    userStaked: '0',
    startDate: '0',
};
