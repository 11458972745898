import EnLocales from '../translation/translations/en.json';
import ZhLocales from '../translation/translations/zh.json';

export const LANGUAGES = ['en', 'zh'];
export const RESOURCES_LANGUAGES = {
    en: {
        translation: EnLocales,
    },
    zh: {
        translation: ZhLocales,
    },
};
