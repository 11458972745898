import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  return {
    text: css`
      font-size: 13px;
      color: #FCFCFF;
      ${theme.breakpoints.down('md')}{
        font-size: 12px;
      }
    `,
  };
};
