import { Staking } from 'types/contracts';
import BigNumber from "bignumber.js";
import convertWeiToTokens from "../../../../utilities/convertWeiToTokens";
import { NetworkState, TPYChainId } from '../../../../types';

export interface GetPoolInfoInput {
  stakeContract: Staking;
  stakeContractOtherNetwork: Staking;
  poolIds: number[];
  chainId: TPYChainId;
  network: NetworkState;
}

export interface PoolInfo{
  poolId: number;
  totalStakes: BigNumber;
}

export type GetPoolInfoOutput = {
  poolInfo: {
    res: { [network: number]: {[p: number]: PoolInfo} };
    locked: number;
    totalStakes: number;
  };
};

const getPoolInfo = async ({
  stakeContract,
  stakeContractOtherNetwork,
  poolIds,
  network
}: GetPoolInfoInput): Promise<GetPoolInfoOutput> => {
  const res: {[network: number]: { [poolId: number]: PoolInfo }} = {
    [network]: {},
    [network === NetworkState.ETH ? NetworkState.ARBITRUM : NetworkState.ETH]: {},
  };  let locked = 0;
  let allTotalStakes = 0;
  let lockedOtherNetwork = 0;
  let allTotalStakesOtherNetwork = 0;
  for (const poolId of poolIds) {
    const resP = await stakeContract.methods.poolInfo(poolId).call();
    const resPOtherNetwork = await stakeContractOtherNetwork.methods.poolInfo(poolId).call();

    locked += +convertWeiToTokens({valueWei: new BigNumber(resP.lockPeriod), tokenId: 'tpy', network: network});
    lockedOtherNetwork += +convertWeiToTokens({valueWei: new BigNumber(resPOtherNetwork.lockPeriod), tokenId: 'tpy', network: network});

    allTotalStakes += +convertWeiToTokens({valueWei: new BigNumber(resP.totalStakes), tokenId: 'tpy', network: network});
    allTotalStakesOtherNetwork += +convertWeiToTokens({valueWei: new BigNumber(resPOtherNetwork.totalStakes), tokenId: 'tpy', network: network});

    res[network][poolId] = {
      poolId: poolId,
      totalStakes: new BigNumber(resP.totalStakes)
    }
    res[network === NetworkState.ETH ? NetworkState.ARBITRUM : NetworkState.ETH][poolId] = {
      poolId: poolId,
      totalStakes: new BigNumber(resPOtherNetwork.totalStakes)
    }
  }
  return {
    poolInfo: {
      res,
      locked: locked + lockedOtherNetwork,
      totalStakes: allTotalStakes + allTotalStakesOtherNetwork
    },
  };
};

export default getPoolInfo;
