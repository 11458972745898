import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  return {
    pool: css`
      width: fit-content;
      display: flex;
      padding: 10px 11px;
      background-color: ${theme.palette.customBackground.navItems};
      border-radius: 6px;
      gap: 13px;
      align-items: center;
      border: 1px solid ${theme.palette.customBackground.poolBorder};
      ${theme.breakpoints.down('md')} {
        align-self: flex-start;
      }
    `,
    emptyPool: css`
      display: flex;
      justify-content: space-between;
      align-items: center;
    `,
    poolContainer: css`
      display: flex;
      justify-content: space-between;
      align-items: center;
    `,
    actions: css`
      display: flex;
      gap: 12px;
    `,
    tpy: css`
      font-size: 14px;
      font-weight: 500;
      color: ${theme.palette.text.primary};
    `,
    earn: css`
      font-size: 12px;
      color: ${theme.palette.text.secondary};
    `,
  };
};
