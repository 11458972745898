import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

import { Variant } from './types';

export const styles = ({
  fullWidth,
  variant,
  small,
}: {
  fullWidth: boolean;
  variant: Variant;
  small: boolean;
}) => {
  const theme = useTheme();

  const getButtonVariantCss = (refVariant: Variant) => {
    if (refVariant === 'secondary') {
      return css`
        background: ${theme.palette.button.border};
        border: 2px solid transparent;
        color: ${theme.palette.customText.secondary};
        :disabled {
          opacity: 0.24;
        }

        :hover:not(:disabled) {
          background-color: ${theme.palette.button.main};
          border-color: ${theme.palette.button.main};
        }

        :active:not(:disabled) {
          background-color: ${theme.palette.button.medium};
          border-color: ${theme.palette.button.medium};
        }
        ${theme.breakpoints.down('md')} {
          border-radius: 8px;
          padding: ${small ? theme.spacing(2.25, 3) : theme.spacing(2.25, 6)};
        }
      `;
    }

    if (refVariant === 'tertiary') {
      return css`
        background-color: ${theme.palette.button.secondary};
        border: none;
        border-radius: 8px;

        :disabled {
          background-color: ${theme.palette.button.groupDisable};
          color: ${theme.palette.text.primary};
        }

        :hover:not(:disabled) {
          background-color: ${theme.palette.button.groupDisable} !important;
        }

        :active:not(:disabled) {
          background-color: ${theme.palette.button.groupDisable};
        }
        ${theme.breakpoints.down('md')} {
          border-radius: 8px;
          padding: ${small ? theme.spacing(2.25, 3) : theme.spacing(2.25, 6)};
        }
      `;
    }

    // Primary variant
    return css`
      background: ${theme.palette.button.main};
      border: none;
      align-items: center;
      :disabled {
        background: ${theme.palette.button.disable};
      }
      color: ${theme.palette.customText.primary};
      :hover:not(:disabled) {
        background-color: ${theme.palette.button.medium};
        border-color: ${theme.palette.button.medium};
      }

      :active:not(:disabled) {
        background-color: ${theme.palette.button.dark};
        border-color: ${theme.palette.button.dark};
      }
      ${theme.breakpoints.down('md')} {
        border-radius: 8px;
        padding: ${small ? theme.spacing(2.25, 3) : theme.spacing(2.25, 2)};
      }
    `;
  };

  return {
    getButton: ({ disabled }: { disabled: boolean }) => css`
      border-radius: 8px;
      min-width: ${small ? '60px' : '114px'};
      padding: ${small ? theme.spacing(2.25, 3) : theme.spacing(2.5, 3.5)};
      box-shadow: none;
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${theme.palette.text.primary};
      transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

      :disabled {
        color: ${theme.palette.text.secondary};
      }

      ${!disabled &&
      css`
        cursor: pointer;
      `}

      ${fullWidth &&
      css`
        width: 100%;
      `};

      ${getButtonVariantCss(variant)};
      
      
    `,
    loadingIcon: css`
      margin-right: ${theme.spacing(2)};
      margin-top: -3px;
      margin-bottom: -3px;
    `,
    label: css`
      display: inline-flex;
      align-items: center;
      font-weight: 500;
      color: inherit;
      gap: 10px;
      font-size: 14px;
      ${theme.breakpoints.down('md')} {
        gap: 5px;
      }
    `,
    link: css`
      font-weight: 600;
    `,
  };
};

export default styles;
