/** @jsxImportSource @emotion/react */
import Typography from '@mui/material/Typography';
import config from 'config';
import React from 'react';

import { Connector } from 'clients/web3';
import { useTranslation } from 'translation';

import {
  WALLETS,
} from '../constants';
import { useStyles } from './styles';
import {Box} from "@mui/material";

export interface WalletListProps {
  onLogin: (connector: Connector) => void;
}

export const WalletList: React.FC<WalletListProps> = ({ onLogin }) => {
  const styles = useStyles();
  const { t } = useTranslation();
  return (
    <div css={styles.container}>
        <Typography css={styles.subtitle}>
            {t('authModal.title.connectWalletSelected')}
        </Typography>
      <div css={styles.walletList}>
        {WALLETS.filter(({ mainnetOnly }) => !mainnetOnly || !config.isOnTestnet).map(
          ({ name, connector, Logo }) => (
            <button
              css={styles.getListItem({ isActionable: true })}
              key={`wallet-${name}`}
              type="button"
              onClick={() => onLogin(connector)}
            >
                <Box>
                    <Logo css={styles.walletLogo} />
                </Box>
              <Typography variant="tiny" component="div" sx={{display: 'flex', width: '80%', justifyContent: 'center'}}>
                  {name}
              </Typography>
            </button>
          ),
        )}
      </div>
    </div>
  );
};
