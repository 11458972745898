/** @jsxImportSource @emotion/react */
import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
import Box from "@mui/material/Box";
import {Mode} from "../../types";
import {useTranslation} from 'translation';
import CloseIcon from '@mui/icons-material/Close';

import ThrupennyLogoLight from "../../assets/img/thrupennyLogoLight.svg";
import ThrupennyLogo from "../../assets/img/thrupennyLogo.svg";
import {changeMode, getMode, useAppDispatch, useAppSelector} from "../../features";
import {useStyles} from './styles';
import {Typography, useTheme} from "@mui/material";
import {SwitchComponent, SwitchLanguageComponent} from "../Switch";
import {Line} from "../Line";
import SocialMedia from "../Layout/Footer/components/socialMedia";
import SelectNetworkButton from 'components/Layout/SelectNetworkButton';

export const DrawerComponent: React.FC = () => {
    const mode = useAppSelector(getMode);
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const {t, i18n} = useTranslation();
    const [right, setRight] = React.useState(false);
    const styles = useStyles();
    const toggleDrawer =
        (open: boolean) =>
            (event: React.KeyboardEvent | React.MouseEvent) => {
                if (
                    event.type === 'keydown' &&
                    ((event as React.KeyboardEvent).key === 'Tab' ||
                        (event as React.KeyboardEvent).key === 'Shift')
                ) {
                    return;
                }
                setRight(open);
            };
    const handleClose = () => {
        setRight(false);
    }
    const handleMode = () => {
        dispatch(changeMode((mode === Mode.LIGHT && Mode.DARK) || Mode.LIGHT));
    };
    const handleChange = () => {
        const lang = localStorage.getItem('i18next');
        i18n.changeLanguage(lang === "en" ? "zh" : "en");
        localStorage.setItem('i18next', lang === "en" ? "zh" : "en");
    };
    return (
        <div>
            <React.Fragment>
                <Button onClick={toggleDrawer(true)}>
                    <MenuIcon fontSize="large" htmlColor={theme.palette.customBackground.icon} />
                </Button>
                <Drawer
                    anchor={'right'}
                    open={right}
                    onClose={toggleDrawer(false)}
                    PaperProps={{
                        sx: {
                            background: theme.palette.customBackground.drawer,
                            backdropFilter: 'blur(4px)',
                            width: '100%'
                        }
                    }}
                >
                    <Box sx={{minWidth: 360, padding: '16px'}}>
                        <Box css={styles.drawer}>
                            <div>
                                <img
                                    src={mode === Mode.LIGHT ? ThrupennyLogoLight : ThrupennyLogo}
                                    srcSet={`${mode === Mode.LIGHT ? ThrupennyLogoLight : ThrupennyLogo}?fit=crop&auto=format&dpr=2 2x`}
                                    alt="logo"
                                    loading="lazy"
                                    height={20}
                                />
                            </div>
                            <Button css={styles.closeIcon} onClick={handleClose}>
                                <CloseIcon htmlColor={theme.palette.customBackground.icon} />
                            </Button>
                        </Box>

                        <Box sx={{marginTop: '55px'}}>
                            <Box css={styles.switches}>
                                <Typography css={styles.drawerText}>
                                    Select Network
                                </Typography>
                                <Box >  <SelectNetworkButton /> </Box>

                            </Box>
                            <Box css={styles.switches}>
                                <Typography css={styles.drawerText}>
                                    {t('drawer.languages')}
                                </Typography>
                                <SwitchLanguageComponent change={handleChange} />
                            </Box>
                            <Box css={styles.switches}>
                                <Typography css={styles.drawerText}>
                                    {mode === Mode.LIGHT ? t('drawer.light') : t('drawer.dark')}
                                </Typography>
                                <SwitchComponent change={handleMode} />
                            </Box>
                        </Box>

                        <Box sx={{padding: '0 8px', margin: '24px 0'}}>
                            <Line />
                        </Box>

                        <Box sx={{display: 'flex', flexDirection: 'column', gap: '19px'}}>
                            <Typography component="span">
                                <a href="https://app.thrupenny.io/" target={'_blank'} css={styles.products}>
                                    {t('footer.yieldFarming')}
                                </a>
                            </Typography>
                        </Box>
                        <Box sx={{padding: '0 8px', margin: '16px 0 24px 0'}}>
                            <Line />
                        </Box>
                        <Box>
                            <Typography component="span">
                                <a href="https://thrupenny.io/" target={'_blank'} css={styles.drawerText}>
                                    {t('footer.projects')}
                                </a>
                            </Typography>
                        </Box>
                        <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <Box>
                                <Typography css={styles.drawerText}>
                                    {t('drawer.socialMedia')}
                                </Typography>
                            </Box>
                            <Box>
                                <SocialMedia />
                            </Box>
                        </Box>
                    </Box>
                </Drawer>
            </React.Fragment>
        </div>
    );
}
