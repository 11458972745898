import type { TransactionReceipt } from 'web3-core';

import { Staking } from 'types/contracts';
import {checkForTokenTransactionError} from "../../../../errors";
import convertTokensToWei from "../../../../utilities/convertTokensToWei";
import BigNumber from "bignumber.js";
import {NetworkState} from "../../../../types";

export interface WithdrawInput {
  stakingContract: Staking;
  accountAddress: string;
  poolId: number;
  amount: number;
  network: NetworkState;
}

export type WithdrawTokenOutput = TransactionReceipt;

const withdrawToken = async ({
                        stakingContract,
                        accountAddress,
                        poolId,
                        amount,
                        network
                      }: WithdrawInput): Promise<WithdrawTokenOutput> =>{
    const resp = await stakingContract.methods.unstake(poolId, (convertTokensToWei({
        value: new BigNumber(amount.toFixed(8)),
        tokenId: 'tpy',
        network: network
    }).toString())).send({ from: accountAddress });

    return checkForTokenTransactionError(resp);
}

export default withdrawToken;
