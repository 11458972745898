import { QueryObserverOptions, useQuery } from 'react-query';
import { TokenId } from 'types';
import { NetworkState } from "../../../../types"

import FunctionKey from 'constants/functionKey';
import getPriceOf from "./index";

export interface GetPriceOfInput {
  accountAddress: string;
}

export type GetPriceOfOutput = {
  price: number;
};

type Options = QueryObserverOptions<
  GetPriceOfOutput,
  Error,
  GetPriceOfOutput,
  GetPriceOfOutput,
  [FunctionKey.GET_TOKEN_PRICE, string, string]
>;

const useGetPriceOf = (
  { accountAddress, tokenId, network }: Omit<GetPriceOfInput, 'tokenContract'> & { tokenId: TokenId; network: NetworkState },
  options?: Options,
) => {
  return useQuery(
      [FunctionKey.GET_TOKEN_PRICE, accountAddress, tokenId],
      () => getPriceOf({tokenId , network } ),
      options,
  );
};

export default useGetPriceOf;
