// import { css } from '@emotion/react';
import {css, useTheme} from "@mui/material";

export const useStyles = () => {
  const theme = useTheme();
  return {
    socialMedia: css `
      display: flex;
      gap: 24px;
      & > a {
        width: 28px;
        height: 28px;
        background: ${theme.palette.customBackground.social};
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        & > svg {
          width: 16px;
          height: 16px;
        }
      }
      ${theme.breakpoints.down('md')} {
        gap: 13px;
      }
    `,
    icons: css`
      & > path {
        fill: ${theme.palette.text.primary};
      }
    `,
  };
};
