import BigNumber from 'bignumber.js';

import { TOKENS } from 'constants/tokens';

export enum TPYChainId {
  'MAINNET' = 1,
  'TESTNET' = 5,
  'ARBITRUM_ONE' = 42161,
  'ARBITRUM_GOERLI'= 421613,
}

export enum NetworkState {
  ETH = "1",
  ARBITRUM = "42161",
}
export enum Mode {
  LIGHT = 'light',
  DARK = 'dark',
}


export type TokenId = keyof typeof TOKENS;

export interface Asset {
  id: TokenId;
  tokenPrice: BigNumber;
  symbol: string;
  borrowBalance: BigNumber;
  decimals: number;
  walletBalance: BigNumber;
  img: string;
  borrowCaps: BigNumber;
  supplyApy: BigNumber;
  collateralFactor: BigNumber;
  collateral: boolean;
  supplyBalance: BigNumber;
  key: number;
  percentOfLimit: string;
  tokenAddress: string;
}

export interface Token {
  id: TokenId;
  symbol: Uppercase<TokenId>;
  decimals: number;
  address: string | '';
  asset: string;
  coingeckoId: string;
}

export interface PoolType {
  "poolId": number;
  "percent": number;
  "term": number;
  "startDate"?: string;
  "endDate"?: string;
  "total": string;
  "accountBalance": string;
  "disabled": boolean;
}
