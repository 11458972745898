/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';

import Box from '@mui/material/Box';

import Navbar from './Navbar';
import {PageContainer} from './PageContainer';
import {useStyles} from './styles';
import Footer from "./Footer";
import {useDispatch} from "react-redux";
import {useWeb3Account} from "../../clients/web3";
import {NetworkState} from "../../types";
import {changeConfig} from "../../features";

export const Layout: React.FC = ({children}) => {
    const styles = useStyles();
    const dispatch = useDispatch();
    const {chainId} = useWeb3Account();
    useEffect(() => {
        if (chainId?.toString() === NetworkState.ETH || chainId?.toString() === NetworkState.ARBITRUM){
            dispatch(changeConfig({ network : chainId?.toString() as NetworkState}))
        }
    }, [chainId])
    return (
        <>
            <Navbar />
            <div css={styles.main}>
                <div css={styles.background}>
                    <div css={styles.layout}>
                        <Box display="flex" flexDirection="column" flex="1">
                            <PageContainer>{children}</PageContainer>
                        </Box>
                        <Footer />
                    </div>
                </div>
            </div>
        </>

    );
};
