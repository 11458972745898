/** @jsxImportSource @emotion/react */
import React, {useState} from 'react';

import {ReactComponent as CopyIcon} from '../../../../assets/img/icons/copyIcon.svg';

import {useStyles} from './styles';
import {Box, Snackbar, Typography} from "@mui/material";
import {useGetAddressTold} from "../../../../clients/api";
import {AuthContext} from "../../../../context/AuthContext";
import { getConfig } from 'features';
import { useSelector } from 'react-redux';

const ReferralLink: React.FC = () => {
    const styles = useStyles();
    const {account} = React.useContext(AuthContext);
    const { chainId } = useSelector(getConfig);
    const [open, setOpen] = useState(false);

    const {data} = useGetAddressTold(
        {
            accountAddress: account?.address || '',
            chainId: chainId
        },
        {
            enabled: !!account?.address,
        },
    );

    const copyLink = () => {
        setOpen(true);
        navigator.clipboard.writeText(`${process.env.REACT_APP_URL || 'https://staking.thrupenny.io/'}stake/${chainId}/${data?.userId}`);
    }

    return (
        <>
            <Snackbar
                message="Copied to link"
                autoHideDuration={2000}
                onClose={() => setOpen(false)}
                open={open}
                anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            />
            <Box css={styles.referralContainer}>
                <div>
                    <Typography css={styles.link}>
                        {`${process.env.REACT_APP_URL || 'https://staking.thrupenny.io/'}stake/${chainId}/${data?.userId}`}
                    </Typography>
                </div>
                <Box css={styles.icons}>
                    <Box sx={{display: 'flex', cursor: 'pointer'}} onClick={copyLink}>
                        <CopyIcon css={styles.icon} />
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default ReferralLink;

