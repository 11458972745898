export interface NumberFormatInput {
    value: string | null | number;
}

export type NumberFormatOutput<T> = T extends true ? string : 0;

function numberFormat <T extends string>({ value }: NumberFormatInput): NumberFormatOutput<T> {
    return (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        (value && new Intl.NumberFormat('en-EN').format(value)) || 0
    ) as NumberFormatOutput<T>;
}
export default numberFormat;
