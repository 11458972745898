import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = ({ isEmptyPool } : {isEmptyPool?: boolean}) => {
  const theme = useTheme();

  return {
    pool: css`
      width: 100%;
      padding: 15px 19px;
      border-radius: 12px;
      background: ${theme.palette.customBackground.navBar};
      box-shadow: none;
      ${theme.breakpoints.down('sm')} {
        padding: 12px 14px 24px;
        background: ${isEmptyPool ? 'transparent' : theme.palette.customBackground.navBar};
      }
    `,
    emptyPool: css`
      display: flex;
      justify-content: space-between;
      align-items: center;
      ${theme.breakpoints.down('lg')} {
        flex-direction: column;
        align-items: flex-start;
      }
    `,
    buttons: css`
      ${theme.breakpoints.down('lg')} {
        margin-top:21px;
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }
    `,
    poolContainer: css`
      display: flex;
      justify-content: space-between;
      align-items: center;
      ${theme.breakpoints.down('lg')} {
        flex-direction: column;
      }
    `,
    actions: css`
      min-width: 240px;
      display: flex;
      justify-content: flex-end;
      gap: 12px;
      ${theme.breakpoints.down('lg')} {
        width: 100%;
        justify-content: space-between;
      }
      ${theme.breakpoints.down('md')} {
        & > button {
          min-width: 140px;
          height: 36px;
        }
      }
    `,
  };
};
