/** @jsxImportSource @emotion/react */
import React, {useEffect, useState} from 'react';
import { useTranslation } from 'translation';
import {Modal} from '../Modal';
import {useSelector} from "react-redux";
import {changeType, getConfig, getStakeModal, reset, useAppDispatch} from "../../features";
import {Box, Input, Tooltip, Typography, useTheme} from "@mui/material";
import {PoolIcon} from "../Pool/components/Pool";
import {Button} from "../Button";
import { useStyles } from "./styles";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {CardComponent} from "../Card";
import {ButtonGroup} from "../ButtonGroup";
import TPYIcon from '../../assets/img/icons/thrupennyIcon.svg'
import {useGetBalanceOf, useApproveToken, useGetAllowance, useStakeToken, useWithdrawToken, GetPriceOfOutput} from "../../clients/api";
import convertWeiToTokens from "../../utilities/convertWeiToTokens";
import {AuthContext} from "../../context/AuthContext";
import getContractAddress from "../../utilities/getContractAddress";

import {ReactComponent as SuccessIcon } from '../../assets/img/icons/successIcon.svg';
import {ReactComponent as LinkIcon} from "../../assets/img/icons/linkIcon.svg";
import {TX_URLS} from "../../constants/endpoints";
export interface StakeModalProps {
  price: GetPriceOfOutput | undefined;
  referralId: number;
}

export const StakeModal: React.FC<StakeModalProps> = ({price, referralId}) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const { account } = React.useContext(AuthContext);
  const dispatch = useAppDispatch();
  const { network } = useSelector(getConfig);
  const { isOpen, percent, date, type, poolId, startDate, userStaked, stakeDisabled } = useSelector(getStakeModal);
  const startTerm = startDate ? new Date(+startDate * 1000): new Date();
  const endDate = new Date();
  const [balance, setBalance] = useState('0');
  const [amount, setAmount] = useState('');
  const [txHash, setTxHash] = useState('');
  endDate.setDate(endDate.getDate() + date);
  const { chainId } = useSelector(getConfig);
  const spenderAddress = getContractAddress('staking',chainId);
  const { mutate: contractApproveToken, isLoading: approveTokenLoading,  } = useApproveToken({
    tokenId: 'tpy',
    network: network
  });
  const { mutateAsync: stakeTPYToken, isLoading: stakingLoading } = useStakeToken({accountAddress: account?.address || '', chainId: chainId});

  const { mutateAsync: withdrawTPYToken, isLoading: withdrawLoading } = useWithdrawToken({accountAddress: account?.address || '', chainId: chainId});

  const { data: getTokenAllowanceData } = useGetAllowance(
      {
        accountAddress: account?.address || '',
        spenderAddress,
        tokenId: 'tpy',
        network: network

      },
      {
        enabled: !!account?.address,
      },
  );
  const isTokenApproved = (!!getTokenAllowanceData && getTokenAllowanceData.allowanceWei.isGreaterThan(0));

  const { data: availableTokensData, isLoading, status } = useGetBalanceOf(
      {
        accountAddress: account?.address || '',
        tokenId: 'tpy',
        network: network
      },
      {
        enabled: !!account?.address,
      },
  );
  useEffect(() => {
    if(status === "success"){
      const balanceValue = convertWeiToTokens({valueWei: availableTokensData.balanceWei, tokenId: 'tpy', network: network});
      setBalance(balanceValue.toString());
    }
  },[isLoading, account?.address, network, status])

  const onClose = () => {
    setTxHash('');
    setAmount('')
    dispatch(reset());
  }
  const changeButton = (index: number) => {
    setAmount('');
    dispatch(changeType({type: (index ? 'withdraw' : 'stake')}));
  }
  const changeAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
    const maxAmount = (type === "withdraw" && userStaked) || balance;
    if((!isNaN(+event.target.value) && !isNaN(parseFloat(event.target.value))) || (event.target.value[event.target.value.length - 1] === '.' && event.target.value.indexOf('.') === event.target.value.length - 1)){
      if(+event.target.value > +maxAmount){
        return setAmount(maxAmount);
      }
      return setAmount(event.target.value);
    }
    if(event.target.value === ''){
      return setAmount('');
    }
  }
  const allowanceBalance = () => {
    contractApproveToken({
      accountAddress: account?.address || '',
      spenderAddress,
    })
  }
  const stakeTpy = async () => {
    if(type === "withdraw"){
      let maxAmount = +amount;
      if(amount === userStaked){
        maxAmount = +amount * 10;
      }
      const res = await withdrawTPYToken({
        accountAddress: account?.address || '',
        poolId: poolId,
        amount: maxAmount,
        network:network
      })
      return setTxHash(res.transactionHash);
    }else{
      const res = await stakeTPYToken({
        accountAddress: account?.address || '',
        poolId: poolId,
        referralId: referralId,
        amount: amount,
        network: network
      })
      return setTxHash(res.transactionHash);
    }
  }
  const setAllBalance = () => {
    setAmount((type === "withdraw" && userStaked) || balance);
  }
  const getEndDate = () => {
    return type==="withdraw"
        ? `${startTerm.toLocaleString('default', {month: 'short'})} ${startTerm.getDate()},${startTerm.getFullYear()}`
        : `${endDate.toLocaleString('default', {month: 'short'})} ${endDate.getDate()},${endDate.getFullYear()}`
  }
  return (
    <Modal
      className="thrupenny-modal"
      isOpen={isOpen}
      handleClose={onClose}
      noHorizontalPadding={!account}
      title={''}
    >
        <Box sx={{
          padding: '25px 0 0'
        }}>
          {!txHash ? <>
            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
              <Box>
                <PoolIcon />
              </Box>
              <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                <Typography css={styles.percent}>
                  {percent} %
                </Typography>
                <Typography css={styles.lockDay}>
                  {date ? `${date} ${t('stakeModal.daysLock')}` : t('stakeModal.flexible')}
                </Typography>
              </Box>
            </Box>
            <Box sx={{marginTop: '20px'}}>
              <CardComponent variant="modal">
                <ButtonGroup
                    buttonLabels={[
                      { label: t("layout.stake.pools.stake"), disabled: stakeDisabled},
                      {label: t("layout.stake.pools.withdraw"), disabled: false}]
                    }                    activeButtonIndex={type === 'stake' ? 0 : 1}
                    onButtonClick={changeButton}
                    fullWidth
                />
                <Box css={styles.balances}>
                  <Box css={styles.amount}>
                    <div>
                      <Input
                          onChange={changeAmount}
                          inputProps={{pattern: "[0-9]"}}
                          disableUnderline
                          placeholder={'0'}
                          value={amount}
                      />
                    </div>
                    <Box sx={{display: 'flex', align: 'center', justifyContent: 'center', gap: '7px'}}>
                      <img src={TPYIcon} alt="..." width={24} height={24} />
                      <Typography css={styles.tpy}>
                        TPY
                      </Typography>
                    </Box>
                  </Box>
                  <Box css={styles.amount} sx={{marginTop: {lg: '2px', md: '14px'}}}>
                    <div>
                      $ {((+amount) * (price?.price || 0)).toFixed(2)}
                    </div>
                    <Box sx={{display: 'flex', gap: '14px'}}>
                      <Typography css={styles.balance}>
                        {userStaked && type === "withdraw" ? t('stakeModal.alreadyStaked') : t('stakeModal.balance')} {(+((type === "withdraw" && userStaked) || balance)).toFixed(2)}
                      </Typography>
                      <Typography css={styles.maxAmount} onClick={setAllBalance}>
                        {t('stakeModal.max')}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </CardComponent>
            </Box>
            <Box sx={{display: 'flex', justifyContent: 'space-between', marginTop: '24px'}}>
              <Typography component={'div'} css={styles.columnsTitle}>
                {t('stakeModal.lockEnd')}
                <Tooltip title={type === "withdraw" ? t('info.lockEndDateWithdraw') : t('info.lockEndDateStake')} placement="top" arrow>
                  <InfoOutlinedIcon fontSize={'small'} />
                </Tooltip>
              </Typography>
              <Typography css={styles.lockDay}>
                {date ? getEndDate() : t('stakeModal.flexible')}
              </Typography>
            </Box>
            <Box css={styles.buttons}>
              {type === "stake"
                  ? <Button fullWidth={true} variant="primary" disabled={!amount || isTokenApproved}
                            onClick={allowanceBalance}
                            loading={approveTokenLoading}>{isTokenApproved ? t('stakeModal.approved') : t('stakeModal.approvedToContinue')}</Button>
                  : null
              }
            </Box>
            <Box sx={{marginTop: '16px'}}>
              <Button loading={stakingLoading || withdrawLoading} fullWidth={true} variant="primary"
                      disabled={!amount || !isTokenApproved} onClick={stakeTpy}>
                {userStaked && type === "withdraw" ? t('stakeModal.unstaked') : t('stakeModal.staked')}
              </Button>
            </Box>
          </>
              :<Box>
                <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                  <Typography css={styles.allDone}>
                    {t('successModal.done')}
                  </Typography>
                  <Box sx={{height: '140px'}}>
                    <SuccessIcon />
                  </Box>
                  <Typography css={styles.reviewDetailsText}>
                    {type === "stake" ? t('successModal.staked') :t('successModal.withdraw')} {amount} TPYs
                  </Typography>
                </Box>
                <Box>
                  <Box css={styles.reviewDetails}>
                    <a href={`${TX_URLS[chainId]}tx/${txHash}`} target="_blank">
                      <Typography css={styles.reviewDetailsText}>
                        {t('successModal.reviewDetails')}
                      </Typography>
                      <LinkIcon fill={theme.palette.text.secondary} width={15} height={15}/>
                    </a>
                  </Box>
                  <Button variant={'secondary'} fullWidth onClick={onClose}>
                    {t('successModal.close')}
                  </Button>
                </Box>
              </Box>
          }
        </Box>
    </Modal>
  );
};
