import BigNumber from 'bignumber.js';
import React from 'react';
import ReactDOM from 'react-dom';
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';

import 'assets/styles/index.scss';
// eslint-disable-next-line import/no-cycle
import App from 'pages/App';
// eslint-disable-next-line import/no-cycle
import { themeSlice, stakeModalSlice, configSlice } from './features';

// Initialize BigNumber format
BigNumber.config({
  FORMAT: {
    decimalSeparator: '.',
    groupSize: 3,
    groupSeparator: ',',
  },
});
const store = configureStore({
  reducer: {
    theme: themeSlice.reducer,
    stakeModal: stakeModalSlice.reducer,
    config: configSlice.reducer,
  },
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('root'));
