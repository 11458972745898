import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import React, { ReactNode } from 'react';

import 'assets/styles/normalize.scss';
import mainTheme from './muiTheme';
import { getMode, useAppSelector } from '../../features';

export function MuiThemeProvider({ children }: { children: ReactNode }) {
    const mode = useAppSelector(getMode);
  return (
    <ThemeProvider theme={mainTheme(mode)}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}
