import { useMemo } from 'react';
import { TokenId, TPYChainId } from 'types';

import { useWeb3 } from 'clients/web3';
import { NetworkState } from '../../types';

import {
  getTokenContract,
  getStakeContract
} from './getters';

export const useTokenContract = <T extends TokenId, G extends  NetworkState>(name: T, network:G) => {
  const web3 = useWeb3(network);

  return useMemo(() => getTokenContract<T, G>(name, network ,web3), [name, network, web3]);
};
export const useStakeContract = (chainId: TPYChainId) => {
  const web3 = useWeb3(chainId);
  return useMemo(() => getStakeContract(web3, chainId), [web3, chainId]);
};
