import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { initialThemeState } from './states';
// eslint-disable-next-line import/no-cycle
import { RootState } from '../index';
import { Mode } from '../types';

export const themeSlice = createSlice({
    name: 'theme',
    initialState: initialThemeState,
    reducers: {
        changeMode: (state, action: PayloadAction<string>) => {
            state.mode = action.payload === Mode.LIGHT ? Mode.LIGHT : Mode.DARK;
            localStorage.setItem('mode', action.payload);
        },
    },
});

export const { changeMode } = themeSlice.actions;

export const getMode = (state: RootState) => state.theme.mode;

export default themeSlice.reducer;
