import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  return {
    percent: css`
      color: ${theme.palette.customText.sometimes};
      font-size: 32px;
      ${theme.breakpoints.down('lg')} {
        font-size: 24px;
      }
    `,
    lockDay: css`
      font-size: 18px;
      color: ${theme.palette.text.primary};
      ${theme.breakpoints.down('lg')} {
        font-size: 14px;
      }
    `,
    columnsTitle: css`
      display: flex;
      gap: 8px;
      align-items: center;
      font-size: 16px;
      color: ${theme.palette.text.secondary};
      ${theme.breakpoints.down('md')} {
        font-size: 12px;
        & > svg{
          width: 12px;
          height: 12px;
        }
      }
    `,
    balances: css`
      margin-top: 24px;
      padding: 20px 24px;
      background: rgba(46, 90, 244, 0.12);
      border: 1px solid #2E5AF4;
      border-radius: 12px;
      ${theme.breakpoints.down('md')} {
        padding: 16px 13px;
      }
    `,
    amount: css`
      display: flex;
      justify-content: space-between;
      ${theme.breakpoints.down('md')} {
        font-size: 12px;
      }
    `,
    maxAmount: css`
      font-weight: 600;
      font-size: 16px;
      color: #2E5AF4;
      cursor: pointer;
      ${theme.breakpoints.down('md')} {
        font-size: 14px;
      }
    `,
    balance: css`
      font-weight: 500;
      font-size: 16px;
      color: ${theme.palette.text.secondary};
      ${theme.breakpoints.down('md')} {
        font-size: 12px;
      }
    `,
    input: css`
      outline: none;
      width: auto;
    `,
    tpy: css`
      font-size: 20px;
      color: ${theme.palette.text.primary};
      font-weight: 600;
      ${theme.breakpoints.down('md')} {
        font-size: 18px;
      }
    `,
    buttons: css`
      margin-top: 50px;
      ${theme.breakpoints.down('md')} {
        margin-top: 40px;
      }
    `,
    allDone: css`
      font-weight: 600;
      font-size: 24px;
      color: ${theme.palette.text.primary};
    `,
    reviewDetails: css`
      display: flex;
      justify-content: flex-end;
      & > a{
        display: flex;
        align-items: center;
        gap: 9px; 
      } 
      margin: 40px 0 20px;
    `,
    reviewDetailsText: css`
      font-weight: 500;
      font-size: 14px;
    `,
  };
};
