import { QueryObserverOptions, useQuery } from 'react-query';

import { useStakeContract } from 'clients/contracts/hooks';
import FunctionKey from 'constants/functionKey';
import { GetAddressToldInput, GetAddressToldOutput } from "./index";
import getAddressTold from "./index";

type Options = QueryObserverOptions<
    GetAddressToldOutput,
    Error,
    GetAddressToldOutput,
    GetAddressToldOutput,
    [FunctionKey.GET_USER_ID, string]
>;

const useGetAddressTold = (
  { accountAddress, chainId }: Omit<GetAddressToldInput, 'stakeContract'>,
  options?: Options,
) => {
  const stakeContract = useStakeContract(chainId);
  return useQuery(
    [FunctionKey.GET_USER_ID, `${accountAddress} ${chainId}`],
    () => getAddressTold({ stakeContract ,accountAddress, chainId }),
    options,
  );
};

export default useGetAddressTold;
