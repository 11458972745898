import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import {useSelector} from "react-redux";
import {getMode} from "../../features";
import modal from "../../assets/img/modalBg.svg";
import modalLigth from "../../assets/img/modalBgLigth.svg";

export const useModalStyles = ({
  hasTitleComponent,
  noHorizontalPadding,
}: {
  hasTitleComponent: boolean;
  noHorizontalPadding?: boolean;
}) => {
  const theme = useTheme();
  const mode = useSelector(getMode);
  return {
    box: css`
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50% , -50%);
      ${theme.breakpoints.down('lg')} {
        left: calc(50% + ${theme.shape.drawerWidthTablet});
        transform: translate(calc(-50% - (${theme.shape.drawerWidthTablet}) / 2), -50%);
      }
      width: 100%;
      max-width: ${theme.spacing(136)};
      border-radius: ${theme.spacing(3)};
      background: ${theme.palette.modal.border};
      border: 4px solid transparent;
      overflow: auto;
      max-height: calc(100% - ${theme.spacing(8)});
      ${theme.breakpoints.down('md')} {
        border: 2px solid transparent;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: calc(100% - 32px);
      }
    `,
    titleWrapper: css`
      padding-left: ${theme.spacing(4)};
      padding-right: ${theme.spacing(4)};
      padding-top: ${theme.spacing(4)};
      padding-bottom: ${hasTitleComponent ? theme.spacing(6) : 0};
      position: sticky;
      top: 0;
      z-index: 10;
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      ${theme.breakpoints.down('md')} {
        padding-left: ${theme.spacing(4)};
        padding-right: ${theme.spacing(4)};
        padding-top: ${theme.spacing(4)};
      }
    `,
    titleComponent: css`
      align-self: center;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: ${theme.shape.iconSize.xLarge}px;
      padding-left: ${theme.shape.iconSize.xLarge}px;
      padding-right: ${theme.shape.iconSize.xLarge}px;
      font-size: 28px;
      font-weight: ${theme.typography.h4.fontWeight};
      ${theme.breakpoints.down('md')} {
        font-size: 18px;
      }
    `,
    closeIcon: css`
      right: ${theme.spacing(4)};
      top: ${theme.spacing(4)};
      position: absolute;
      height: ${theme.shape.iconSize.xLarge}px;
      width: ${theme.shape.iconSize.xLarge}px;
      margin-left: auto;
      min-width: 0;
      padding: 0;
      background-color: transparent;

      :hover {
        background-color: transparent;
      }
    `,
    contentWrapper: css`
      padding-bottom: ${theme.spacing(8)};
      padding-left: ${noHorizontalPadding ? 0 : theme.spacing(10)};
      padding-right: ${noHorizontalPadding ? 0 : theme.spacing(10)};
      ${theme.breakpoints.down('md')} {
        padding-bottom: ${theme.spacing(4)};
        padding-left: ${noHorizontalPadding ? 0 : theme.spacing(4)};
        padding-right: ${noHorizontalPadding ? 0 : theme.spacing(4)};
      }
    `,
    modalBackground: css`
      background: ${mode === "dark" ? `url(${modal})` : `url(${modalLigth})`} no-repeat;
    `,
  };
};
