/** @jsxImportSource @emotion/react */
import React from 'react';

import {Button, TertiaryButton} from "../Button";
import useStyles from './styles';
import {Box, Tooltip} from "@mui/material";
import {useTranslation} from "../../translation";

export interface ButtonGroupProps {
  buttonLabels: { label: string; disabled?: boolean}[];
  activeButtonIndex: number;
  onButtonClick: (newIndex: number) => void;
  fullWidth?: boolean;
  className?: string;
}

export const ButtonGroup: React.FC<ButtonGroupProps> = ({
  buttonLabels,
  activeButtonIndex = 0,
  onButtonClick,
  fullWidth = false,
  className,
}) => {
  const styles = useStyles();
  const { t } = useTranslation();
  return (
      <Box css={styles.groupButtons}>
        <div css={styles.getContainer({ fullWidth })} className={className}>
          {buttonLabels.map((item, index) => (
              item.disabled ? <Tooltip title={t('info.disabledStakeButton')} placement="top" arrow>
                    <Box sx={{ display: 'flex' }} css={styles.getButton({
                      active: index === activeButtonIndex,
                      last: index === buttonLabels.length - 1,
                      fullWidth,
                    })}>
                      <Button variant={'primary'} disabled={item.disabled} fullWidth>{t('layout.stake.pools.stake')}</Button>
                    </Box>
                  </Tooltip>
                  :
                  <TertiaryButton
                      key={`button-group-button-${item.label}`}
                      onClick={() => {
                        onButtonClick(index)
                      }}
                      css={styles.getButton({
                        active: index === activeButtonIndex,
                        last: index === buttonLabels.length - 1,
                        fullWidth,
                      })}
                      disabled={index === activeButtonIndex}
                  >
                    {item.label}
                  </TertiaryButton>
          ))}
        </div>
      </Box>
  );
};
