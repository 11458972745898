import { TokenId, TPYChainId } from 'types';
import { getToken } from 'utilities';
import Web3 from 'web3';
import { AbiItem } from 'web3-utils';

import { getWeb3NoAccount } from 'clients/web3';
import stakeAbi from 'constants/contracts/abis/staking.json';
import tpyAbi from 'constants/contracts/abis/tpy.json';

import { TokenContract } from './types';
import { Staking } from 'types/contracts';
import getContractAddress from "../../utilities/getContractAddress";
// import mainContractAddresses from 'constants/contracts/addresses/main.json';
import { NetworkState } from '../../types'
import {RPC_URLS} from "../../constants/endpoints";

const getContract = <T>(abi: AbiItem | AbiItem[], address: string, chainId: NetworkState | TPYChainId,web3Instance: Web3) => {
    const web3 = web3Instance ?? getWeb3NoAccount(RPC_URLS[chainId]);

    return new web3.eth.Contract(abi, address) as unknown as T;
};

export const getTokenContract = <T extends TokenId, G extends  NetworkState>(tokenId: T, network: G, web3: Web3): TokenContract<T> => {
    const tokenAddress = getToken(tokenId, network).address;
    return getContract<TokenContract<T>>(tpyAbi as AbiItem[], tokenAddress, network, web3);
};

export const getStakeContract = (web3: Web3, chainId: TPYChainId) => {
    const contractAddress = getContractAddress('staking',chainId);
    return getContract(stakeAbi as AbiItem[], contractAddress, chainId, web3, ) as unknown as Staking;
};
