/* https://mui.com/customization/theming/ */
import { ThemeOptions, createTheme } from '@mui/material/styles';

const fontFamily = 'Montserrat';

export const FONTS = {
  primary: fontFamily,
};

export const PALETTE = {
  mode: 'dark',
  background: {
    default: '#2A2741',
    paper: '#2A2741',
  },
  customBackground:{
    default: 'linear-gradient(112deg, #2e2a45 0%, #25264b 100%)',
    drawer: 'linear-gradient(69.18deg, rgba(42, 39, 64, 0.88) 0%, rgba(55, 52, 78, 0.88) 100%)',
    navBar: 'rgba(53, 49, 75, 0.44)',
    navItems: '#2A2741',
    modal: '#2A2741',
    card: '#2A3C68',
    poolBorder: '#3B3960',
    icon: '#FCFCFF',
    social: '#312E47',
    line: '#3B3960',
  },
  button: {
    main: 'linear-gradient(95.17deg, #2F5CF5 1.42%, #57A4F5 100%)',
    secondary: 'rgba(54, 51, 78, 0.8)',
    disable: 'rgba(47, 92, 245, 0.24)',
    groupDisable: 'rgba(46, 90, 244, 0.64)',
    border: 'linear-gradient(rgba(54, 51, 78, 1),rgba(54, 51, 78, 1)) padding-box, linear-gradient(45deg, rgba(46, 90, 244, 1),rgba(88, 166, 244, 1)) border-box',
  },
  modal: {
    border: 'linear-gradient(#2A2741,#2A2741) padding-box, linear-gradient(45deg, rgba(39, 141, 254, 0.18),rgba(39, 141, 254, 0.18)) border-box',
  },
  primary: {
    light: '#EBBF6E',
    main: '#EBBF6E',
    dark: 'var(--color-blue-hover)',
  },
  secondary: {
    light: 'rgba(56, 57, 68, 1)',
    main: 'rgba(40, 41, 49, 1)',
    dark: 'rgba(31, 32, 40, 1)',
  },
  warning: {
    light: 'rgba(53, 49, 75, 0.44)',
    main: '#2A2741',
    dark: 'rgba(31, 32, 40, 1)',
  },
  text: {
    primary: '#FCFCFF',
    secondary: 'rgba(252, 252, 255, 0.64)',
    disabled: 'rgba(252, 252, 255, 0.4)',
  },
  customText: {
    sometimes: '#51CC73',
    primary: '#FCFCFF',
    secondary: '#FCFCFF',
  },
  interactive: {
    primary: 'rgba(49, 46, 71, 0.64)',
    success: 'rgba(59, 57, 96, 1)',
    success50: '#3B3960',
    error: 'rgba(233, 61, 68, 1)',
    error50: 'rgba(233, 61, 68, 0.5)',
    tan: 'rgba(255, 231, 206, 1)',
    delimiter: 'rgba(49, 50, 60, 1)',
    warning: 'rgba(220, 148, 68, 1)',
    hover: 'linear-gradient(rgba(49, 46, 71, 1),rgba(49, 46, 71, 1)) padding-box, linear-gradient(45deg, rgba(47, 92, 245, 1),rgba(87, 164, 245, 1)) border-box',
  },
  switchLang: {
    background: '#3B3960',
    iconBackground: '#FCFCFF',
    activeText: '#3B3960',
    text: 'rgba(255, 255, 255, 0.64)'
  },
  switchMode: {
    background: '#3B3960',
    iconBackground: '#FCFCFF',
    text: 'rgba(255, 255, 255, 0.64)'
  }
};

export const PALETTE_LIGHT = {
  mode: 'light',
  background: {
    default: '#FCFCFF',
    paper: '#FCFCFF',
  },
  customBackground:{
    default: 'linear-gradient(74.36deg, rgba(247, 247, 255, 0.88) 0%, rgba(247, 247, 255, 0.36) 101.26%)',
    drawer: 'rgba(228, 231, 255, 0.64)',
    navBar: '#FCFCFF',
    navItems: 'rgba(228, 231, 255, 1)',
    modal: '#FCFCFF',
    card: '#CDE1FF',
    poolBorder: '#DADEFF',
    icon: '#151323',
    social: '#FCFCFF',
    line: '#DFE2FF',
  },
  button: {
    main: 'linear-gradient(95.17deg, #2F5CF5 1.42%, #57A4F5 100%)',
    medium: 'rgba(38, 90, 204, 1)',
    dark: 'rgba(2, 7, 1, 1)',
    disable: 'rgba(47, 92, 245, 0.24)',
    groupDisable: 'rgba(46, 90, 244, 0.32)',
    border: 'linear-gradient(#FCFCFF,#FCFCFF) padding-box, linear-gradient(45deg, rgba(46, 90, 244, 1),rgba(88, 166, 244, 1)) border-box',
  },
  primary: {
    light: '#EBBF6E',
    main: '#EBBF6E',
    dark: 'var(--color-blue-hover)',
  },
  modal: {
    border: 'linear-gradient(#2A2741,#2A2741) padding-box, linear-gradient(45deg, rgba(39, 141, 254, 0.18),rgba(39, 141, 254, 0.18)) border-box',
  },
  warning: {
    light: '#E4E7FF',
    main: '#FCFCFF',
    dark: 'var(--color-blue-hover)',
  },
  secondary: {
    light: 'rgba(56, 57, 68, 1)',
    main: 'rgba(40, 41, 49, 1)',
    dark: 'rgba(31, 32, 40, 1)',
  },
  text: {
    primary: 'rgba(21, 19, 35, 1)',
    secondary: 'rgba(21, 19, 35, 0.64)',
    disabled: 'rgba(21, 19, 35, 0.44)',
  },
  customText: {
    sometimes: '#51CC73',
    primary: '#FCFCFF',
    secondary: '#2E5AF4',
  },
  interactive: {
    primary: '#E4E7FF',
    success: 'linear-gradient(95.17deg, #2E5AF4 1.42%, #58A6F4 100%)',
    success50: '#3B3960',
    error: 'rgba(233, 61, 68, 1)',
    error50: 'rgba(233, 61, 68, 0.5)',
    tan: 'rgba(255, 231, 206, 1)',
    delimiter: 'rgba(49, 50, 60, 1)',
    warning: 'rgba(220, 148, 68, 1)',
    hover: 'rgba(255, 255, 255, 0.08)',
  },
  switchLang: {
    background: 'rgba(21, 19, 35, 0.64)',
    iconBackground: '#FCFCFF',
    activeText: '#3B3960',
    text: 'rgba(255, 255, 255, 0.64)'
  },
  switchMode: {
    background: 'linear-gradient(95.17deg, #2E5AF4 1.42%, #58A6F4 100%)',
    iconBackground: '#FCFCFF',
    text: 'rgba(255, 255, 255, 0.8)'
  }
};

export const BREAKPOINTS = {
  values: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 1040,
    xl: 1280,
    xxl: 1440,
  },
};

export const SPACING = 4;

export const SHAPE = {
  borderRadius: {
    verySmall: SPACING,
    small: SPACING * 2,
    medium: SPACING * 4,
    large: SPACING * 6,
  } as never, // our custom types seem to clash with the default MUI types
  iconSize: {
    small: SPACING * 3,
    medium: SPACING * 4,
    large: SPACING * 5,
    xLarge: SPACING * 6,
    xxLarge: SPACING * 10,
  },
  footerHeight: '56px',
  drawerWidthDesktop: '224px',
  drawerWidthTablet: '80px',
};


export default function mainTheme(mode = 'dark'){
  return createTheme({
    spacing: SPACING,
    palette: mode === 'dark' ? PALETTE : PALETTE_LIGHT,
    breakpoints: BREAKPOINTS,
    shape: SHAPE,
    typography: {
      fontFamily: FONTS.primary,
      color: PALETTE.text.primary,
      h1: {
        fontSize: '2.5rem',
        fontWeight: 600,
        lineHeight: 1.5,
      },
      span: {
        fontSize: '18px',
        fontWeight: 400,
        color: PALETTE.text.primary,
        '@media (min-width:768px)': {
          fontSize: '14px',
        },
      },
    },
    components: {

    },
  } as ThemeOptions);
}
