import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();
  return {
    select: css`
      & > .MuiOutlinedInput-input {
        display: flex;
        align-items: center;
        gap: 10px;
      },
      & .MuiOutlinedInput-notchedOutline{
        border-color: ${theme.palette.customBackground.card} !important;
      },
      & > fieldset{
        border: 1px solid ${theme.palette.customBackground.card};
        border-radius: 10px;
        &:hover{
          border: 1px solid ${theme.palette.customBackground.card};
        }
        &:focus-visible{
          border: 1px solid ${theme.palette.customBackground.card};
          border-color: ${theme.palette.customBackground.card};
        }
      }
    `,
  };
};
