import sample from 'lodash/sample';
import { TPYChainId } from 'types';

import { ETH_SCAN_URLS } from 'constants/eth';
import { API_ENDPOINT_URLS, RPC_URLS } from 'constants/endpoints';

export interface Config {
  chainId: TPYChainId;
  isOnTestnet: boolean;
  rpcUrl: string;
  apiUrl: string;
  ethScanUrl: string;
  fortmaticApiKey: string;
}

const chainId: TPYChainId = parseInt(localStorage.getItem('network') || process.env.REACT_APP_CHAIN_ID || "1", 10) as TPYChainId;


const isOnTestnet = chainId === TPYChainId.MAINNET;
const rpcUrl = sample(RPC_URLS[chainId]) as string;
const apiUrl = API_ENDPOINT_URLS[chainId];

const ethScanUrl = ETH_SCAN_URLS[chainId];

const fortmaticApiKey = process.env.REACT_APP_FORTMATIC_API_KEY || ' ';

const config: Config = {
  chainId,
  isOnTestnet,
  rpcUrl,
  apiUrl,
  ethScanUrl,
  fortmaticApiKey,
};

export default config;
