import { css } from '@emotion/react';
import {useTheme} from "@mui/material";

export const useStyles = () => {
  const theme = useTheme();
  return {
    footer: css`
      border-radius: 0;
      padding: 0 !important;
      background: transparent;
    `,
    footerLinks: css`
      font-size: 14px;
      color: ${theme.palette.text.primary};
    `,
    footerInfo: css`
      font-size: 14px;
      color: ${theme.palette.text.secondary};
      ${theme.breakpoints.down('sm')} {
        font-size: 12px;
      }
    `,
    footerThrupenny: css`
      display: flex;
      padding: 19px 80px 26px;
      ${theme.breakpoints.down('md')} {
        padding: 20px 18px;
      }
    `,
    pageLinks: css`
      display: flex;
      padding: 0 80px 16px;
      justify-content: space-between;
      ${theme.breakpoints.down('md')} {
        display: none;
      }
    `,
  };
};
