/** @jsxImportSource @emotion/react */
import React from 'react';

import {useTranslation} from 'translation';

import { useStyles } from './styles';
import {Box, Card, Tooltip, Typography} from "@mui/material";
import {Button} from "../Button";
import {toggleModal, useAppDispatch} from "../../features";
import ConnectButton from "../Layout/ConnectButton";
import EastIcon from "@mui/icons-material/East";
import {PoolIcon} from "./components/Pool";
import {PoolInfo} from "./components/PoolInfo";

export interface PoolProps {
    isEmpty: boolean;
    percent: number;
    startDate?: string;
    term: number;
    stake: string;
    accountStake: string;
    disable: boolean;
    account: boolean;
    type: string;
    poolId: number;
    disabled: boolean;
}

export interface EmptyPoolProps {
    account: string| undefined;
    scrollTo: () => void;
}


export const PoolUI: React.FC<{ children: React.ReactNode; isEmptyPool: boolean}> = ({children, isEmptyPool}) => {
  const styles = useStyles({isEmptyPool: isEmptyPool});

  return (
      <Card css={styles.pool}>
        {children}
      </Card>
  );
};

export const ConnectedPool: React.FC<EmptyPoolProps> = ({ account, scrollTo }) => {
    const {t} = useTranslation();
    const styles = useStyles({isEmptyPool: true});
  return (
      <PoolUI isEmptyPool={true}>
          <Box css={styles.emptyPool}>
              <Typography>
                  {t(`layout.stake.pools.${account ? 'emptyPool' : 'engagedConnect'}`)}
              </Typography>
              <Box css={styles.buttons}>
                  {account ? <Button onClick={scrollTo} small={true}>
                      <EastIcon htmlColor={'#fff'}/>
                  </Button> : <ConnectButton />}
              </Box>
          </Box>
      </PoolUI>
  );
};

export const Pool: React.FC<PoolProps> = ({
                                              account,
                                              percent,
                                              term,
                                              startDate,
                                              stake,
                                              accountStake,
                                              type,
                                              poolId,
                                              disabled
                                          }) => {

    const {t} = useTranslation();
    const styles = useStyles({isEmptyPool: false});
    const dispatch = useAppDispatch();
    const openModal = (type: string) => {
        dispatch(toggleModal({
            isOpen: true,
            percent: percent,
            date: term,
            startDate: startDate,
            type: type,
            poolId: poolId,
            userStaked: accountStake,
            stakeDisabled: disabled
        }))
    }
    return <PoolUI isEmptyPool={false}>
        <Box css={styles.poolContainer}>
            <PoolIcon />
            <PoolInfo percent={percent} term={term} startDate={startDate} stake={stake} accountStake={accountStake} type={type}/>
            <Box css={styles.actions}>
                {disabled ?
                    <Tooltip title={t('info.disabledStakeButton')} placement="top" arrow>
                        <Box sx={{ display: 'flex' }}>
                            <Button variant={'primary'} onClick={()=>openModal('stake')} disabled={disabled}>{t('layout.stake.pools.stake')}</Button>
                        </Box>
                    </Tooltip>
                    : <Button variant={'primary'} onClick={()=>openModal('stake')} disabled={account}>{t('layout.stake.pools.stake')}</Button>
                }
                <Button variant={'secondary'} onClick={()=>openModal('withdraw')} disabled={!account}>{t('layout.stake.pools.withdraw')}</Button>
            </Box>
        </Box>
    </PoolUI>;
};
