import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {initialStakeModalState, StakeModalState} from './states';
import { RootState } from '../index';

export const stakeModalSlice = createSlice({
    name: 'stakeModal',
    initialState: initialStakeModalState,
    reducers: {
        toggleModal: (state, action: PayloadAction<StakeModalState>) => {
            state.isOpen = action.payload.isOpen;
            state.percent = action.payload.percent;
            state.date = action.payload.date;
            state.type = action.payload.type;
            state.poolId = action.payload.poolId;
            state.userStaked = action.payload.userStaked;
            state.startDate = action.payload.startDate;
            state.stakeDisabled = action.payload.stakeDisabled;
        },
        changeType: (state, action: PayloadAction<{ type: string }>) => {
            state.type = action.payload.type;
        },
        reset: (state) => {
            state.isOpen = false;
            state.percent = 0;
            state.date = 0;
            state.type = '';
            state.poolId = 0;
            state.userStaked = '0';
            state.startDate = undefined;
            state.stakeDisabled = false;
        }
    },
});

export const { reset, toggleModal, changeType } = stakeModalSlice.actions;

export const getStakeModal = (state: RootState) => state.stakeModal;

export default stakeModalSlice.reducer;
