/** @jsxImportSource @emotion/react */
import Typography from '@mui/material/Typography';
import React from 'react';

import ReactLoading from 'react-loading';

import useStyles from './styles';
import { Variant } from './types';
import {useTheme} from "@mui/material";

export interface BaseButtonProps {
  fullWidth?: boolean;
  small?: boolean;
  variant?: Variant;
}

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    BaseButtonProps {
  loading?: boolean;
}

export const Button = ({
  className,
  loading,
  disabled = false,
  fullWidth = false,
  small = false,
  variant = 'primary',
  children,
  ...otherProps
}: ButtonProps) => {
  const styles = useStyles({ fullWidth, variant, small });
  const theme = useTheme();

  return (
    <button
      css={styles.getButton({ disabled })}
      className={className}
      disabled={loading || disabled}
      type="button"
      {...otherProps}
    >
      <Typography css={styles.label} component="span" variant={small ? 'small1' : 'body1'}>
        {loading && <ReactLoading type={'spinningBubbles'} color={theme.palette.text.secondary} height={16} width={16} />} {children}
      </Typography>
    </button>
  );
};

export const PrimaryButton = (props: ButtonProps) => <Button variant="primary" {...props} />;
export const SecondaryButton = (props: ButtonProps) => <Button variant="secondary" {...props} />;
export const TertiaryButton = (props: ButtonProps) => <Button variant="tertiary" {...props} />;
