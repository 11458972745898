/** @jsxImportSource @emotion/react */
import React from 'react';

import {useTranslation} from 'translation';

import { useStyles } from './styles';
import {Box, Tooltip, Typography} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import numberFormat from "../../../../utilities/numberFormat";

export interface PoolInfoType {
    percent: number;
    term: number;
    startDate?: string;
    stake: string;
    accountStake: string;
    type?: string;
}

export const PoolInfo: React.FC<PoolInfoType> = ({percent, term,startDate, stake, accountStake, type="active"}) => {
    const {t} = useTranslation();
    const styles = useStyles();
    let endDate = new Date();
    if(startDate){
        endDate = new Date(+startDate * 1000);
    }
    return <Box css={styles.poolData}>
        <Box css={styles.poolHeaderMobile}>
            <Box component={'div'} sx={{display: 'flex', flexDirection: 'column', width: '100%', gap: '24px'}}>
                <Typography component={'div'} css={styles.columnsTitle}>
                    {t('layout.stake.pools.reward')}
                    <Tooltip title={t('info.apyReward')} placement="top" arrow enterTouchDelay={10}>
                        <InfoOutlinedIcon fontSize={'small'}/>
                    </Tooltip>
                </Typography>
                <Typography component={'div'} css={styles.columnsTitle} sx={{marginTop: '24px'}}>
                    {t('layout.stake.pools.lockTerm')}
                    <Tooltip title={t('info.lockTerm')} placement="top" arrow enterTouchDelay={10}>
                        <InfoOutlinedIcon fontSize={'small'}/>
                    </Tooltip>
                </Typography>
                <Typography component={'div'} css={styles.columnsTitle}>
                    {t('layout.stake.pools.totalStaked')}
                    <Tooltip title={t('info.totalStaked')} placement="top" arrow enterTouchDelay={10}>
                        <InfoOutlinedIcon fontSize={'small'}/>
                    </Tooltip>
                </Typography>
                <Typography component={'div'} css={styles.columnsTitle}>
                    {t('layout.stake.pools.yourStake')}
                    <Tooltip title={t('info.yourStake')} placement="top" arrow enterTouchDelay={10}>
                        <InfoOutlinedIcon fontSize={'small'}/>
                    </Tooltip>
                </Typography>
            </Box>
        </Box>
        <Box css={styles.infoContainer}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%'}}>
                <Typography css={styles.autoCompoundingPercent}>
                    {percent} %
                </Typography>
                <Typography css={styles.autoCompounding}>
                    {t('layout.stake.pools.autoCompounding')}
                </Typography>
            </Box>
            {startDate?<Box css={styles.lockTerm}>
                <Typography css={styles.infoText}>
                    {
                        term ? <>
                            {endDate.toLocaleString('default', { month: 'short' })}&nbsp;
                            {endDate.getDate()}, {endDate.getFullYear()}
                        </>
                            : t('stakeModal.flexible')
                    }
                </Typography>
            </Box>:<Typography css={styles.infoText}>
                {type === "active"? (term && `${term} ${t('layout.stake.pools.days')}` || t('layout.stake.pools.none')) : term}
            </Typography>}
            <Typography css={styles.infoText}>{numberFormat({value: stake})}</Typography>
            <Typography css={styles.infoText}>{numberFormat({value: (+accountStake).toFixed(2)})}</Typography>
        </Box>
    </Box>;
};
