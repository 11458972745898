import {Layout} from 'components';
import React, {useEffect} from 'react';
import {QueryClientProvider} from 'react-query';
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';

import 'assets/styles/App.scss';
import {queryClient} from 'clients/api';
import {Web3Wrapper} from 'clients/web3';
import {routes} from 'constants/routing';
import {AuthProvider} from 'context/AuthContext';
import Stake from 'pages/Stake';
import {MuiThemeProvider} from 'theme/MuiThemeProvider';
import {changeMode, getMode, useAppDispatch, useAppSelector} from '../features';

const App = () => {
    const mode = useAppSelector(getMode);
    const dispatch = useAppDispatch();

    useEffect(() => {
        const lastMode = localStorage.getItem('mode');
        if (lastMode && lastMode !== mode) {
            dispatch(changeMode(lastMode));
        }
    }, []);

    return <Web3Wrapper>
        <QueryClientProvider client={queryClient}>
            <MuiThemeProvider>
                <AuthProvider>
                    <BrowserRouter>
                        <Layout>
                            <Switch>
                                <Route exact path={routes.stake.path} component={Stake} />
                                <Route exact path={routes.referral.path} component={Stake} />
                                <Redirect to={routes.dashboard.path} />
                            </Switch>
                        </Layout>
                    </BrowserRouter>
                </AuthProvider>
            </MuiThemeProvider>
        </QueryClientProvider>
    </Web3Wrapper>;
};

export default App;
