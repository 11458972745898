import { QueryObserverOptions, useQuery } from 'react-query';
import { useStakeContract } from 'clients/contracts/hooks';
import FunctionKey from 'constants/functionKey';
import getPoolInfo, { GetPoolInfoInput, GetPoolInfoOutput } from './index';
import {TPYChainId} from "../../../../types";

type Options = QueryObserverOptions<
    GetPoolInfoOutput,
    Error,
    GetPoolInfoOutput,
    GetPoolInfoOutput,
    [FunctionKey.GET_POOL_INFO, string,]
>;

const useGetPoolInfo = (
    { poolIds, network, chainId }: Omit<GetPoolInfoInput, 'stakeContract' | 'stakeContractOtherNetwork'>,
    options?: Options,
) => {
  const stakeContract = useStakeContract(chainId);
  const stakeContractOtherNetwork = useStakeContract(chainId === TPYChainId.MAINNET ? TPYChainId.ARBITRUM_ONE : TPYChainId.MAINNET);

  return useQuery(
      [FunctionKey.GET_POOL_INFO, `${poolIds.join(',')}`], // Pass the network state as a query key
      () => getPoolInfo({ stakeContract, stakeContractOtherNetwork, poolIds, network, chainId }),
      options,
  );
};

export default useGetPoolInfo;
