import BigNumber from 'bignumber.js';
import {NetworkState, TokenId} from 'types';
import { getToken } from 'utilities';

export const convertTokensToWei = ({ value, tokenId, network }: { value: BigNumber; tokenId: TokenId; network: NetworkState }) => {
  const tokenDecimals = getToken(tokenId, network).decimals;
  return value.multipliedBy(new BigNumber(10).pow(tokenDecimals));
};

export default convertTokensToWei;
