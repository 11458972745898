import { useWeb3React } from '@web3-react/core';
import { useEffect, useRef, useState } from 'react';
import Web3 from 'web3';

import getWeb3NoAccount from './getWeb3NoAccount';
import config from "../../config";
import {RPC_URLS} from "../../constants/endpoints";
import {NetworkState, TPYChainId} from "../../types";

const useWeb3 = (chainId: TPYChainId | NetworkState) => {
  const { library } = useWeb3React();
  const refEth = useRef(library);
  const [web3, setWeb3] = useState(library ? new Web3(library) : getWeb3NoAccount(RPC_URLS[chainId || config.chainId]));
  useEffect(() => {
    if (library !== refEth.current) {
      setWeb3(library ? new Web3(library) : getWeb3NoAccount(RPC_URLS[chainId]));
      refEth.current = library;
    }
    if((library === refEth.current && chainId !== config.chainId)){
      setWeb3(library ? new Web3(library) :getWeb3NoAccount(RPC_URLS[chainId]));
    }
  }, [library, chainId]);

  return web3;
};

export default useWeb3;
