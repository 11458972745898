import { TPYChainId } from 'types';

export const ETH_SCAN_URLS = {
  [TPYChainId.MAINNET]: 'https://etherscan.io/',
  [TPYChainId.TESTNET]: 'https://goerli-rollup.arbitrum.io/rpc',
  [TPYChainId.ARBITRUM_ONE] :'https://arb1.arbitrum.io/rpc',
  [TPYChainId.ARBITRUM_GOERLI]: 'https://goerli-rollup-explorer.arbitrum.io'
};
export const ETH_SCAN_NAMES = {
  [TPYChainId.MAINNET]: 'Ethereum Mainnet',
  [TPYChainId.TESTNET]: 'Goerli test Network',
  [TPYChainId.ARBITRUM_ONE] :'Arbitrum One',
  [TPYChainId.ARBITRUM_GOERLI]: 'Arbitrum Goerli'
};
export const ETH_SCAN_SYMBOl = {
  [TPYChainId.MAINNET]: 'ETH',
  [TPYChainId.TESTNET]: 'ETH',
  [TPYChainId.ARBITRUM_ONE] :'AGOR',
  [TPYChainId.ARBITRUM_GOERLI]: 'AGOR'
};
