import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const styles = () => {
  const theme = useTheme();
  return {
    line: css`
      width: 100%;
      height: 1px;
      background: ${theme.palette.customBackground.line};
    `,
  };
};

export default styles;
