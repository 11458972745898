import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import {Variant} from "./types";

export const useStyles = ({ variant } : { variant: Variant }) => {
  const theme = useTheme();

  const getCardVariantCss = (refVariant: Variant) => {
    if (refVariant === 'normal') {
      return css`
        border-radius: 18px;
        padding: 24px 12px 30px;
      `;
    }
    if (refVariant === 'small') {
      return css`
        border-radius: 14px;
        padding: 24px;
      `;
    }
    if (refVariant === 'modal') {
      return css`
        border-radius: 18px;
        padding: 16px;
        ${theme.breakpoints.down('sm')} {
          padding: ${variant === 'small' ? '16px' : '24px 0 0'};
          border: none;
          border-radius: 0;
        }
      `;
    }
  };


  return {
    card: css`
      width: 100%;
      height: 100%;
      border: 3px solid ${theme.palette.customBackground.card};
      background: transparent;
      backdrop-filter: blur(12px);
      box-shadow: none;
      ${theme.breakpoints.down('sm')} {
        padding: ${variant === 'small' ? '16px' : '24px 0 0'};
      }
      ${getCardVariantCss(variant)};
    `,
  };
};
