import {API_ENDPOINT_URLS} from 'constants/endpoints';
import {TPYChainId} from "../../../types";
import {ETH_SCAN_NAMES, ETH_SCAN_SYMBOl, ETH_SCAN_URLS} from "../../../constants/eth";

// Prompt the user to add BSC as a network, or switch to BSC if the wallet is on
// a different network
const setUpNetwork = async (chainId: TPYChainId) => {
  if (!window.ethereum) {
    // TODO: send error to Sentry

    console.error("Can't set up the BSC network on wallet because window.ethereum is undefined");
    return false;
  }
  try {
    await window.ethereum.request({
      method: 'wallet_addEthereumChain',
      params: [
        {
          chainId: `0x${chainId.toString(16)}`,
          chainName: ETH_SCAN_NAMES[chainId],
          rpcUrls: [API_ENDPOINT_URLS[chainId]],
          blockExplorerUrls: [`${ETH_SCAN_URLS[chainId]}/`],
          nativeCurrency: {
            name: ETH_SCAN_SYMBOl[chainId],
            symbol: ETH_SCAN_SYMBOl[chainId],
            decimals: 18
          }
        },
      ],
    });

    return true;
  } catch (error) {
    // TODO: send error to Sentry

    console.error('Failed to set up network on wallet:', error);
    return false;
  }
};

export default setUpNetwork;
