import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  return {
    infoContainer: css`
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      ${theme.breakpoints.down('md')} {
        flex-direction: column;
        gap: 24px;
        width: auto;
        & > div{
          align-items: flex-end;
        } 
      }
    `,
    autoCompoundingPercent: css`
      font-size: 17px;
      font-weight: 600;
      color: ${theme.palette.customText.sometimes};

      ${theme.breakpoints.down('md')} {
        font-size: 20px;
      }
    `,
    autoCompounding: css`
      font-weight: 500;
      font-size: 13px;
      color: ${theme.palette.text.secondary};
      ${theme.breakpoints.down('xl')} {
        font-size: 14px;
      }

      ${theme.breakpoints.down('md')} {
        font-size: 12px;
      }
    `,
    infoText: css`
      font-weight: 600;
      text-align: center;
      width: 100%;
      color: ${theme.palette.text.primary};
      font-size: 15px;

      ${theme.breakpoints.down('md')} {
        font-size: 16px;
        text-align: end;
      }
    `,
    lockTerm: css`
      width: 100%;
      font-weight: 600;
      color: ${theme.palette.text.primary};
      font-size: 18px;

      ${theme.breakpoints.down('md')} {
        font-size: 16px;
        text-align: end;
      }
    `,
    poolHeaderMobile: css`
      display: none;

      ${theme.breakpoints.down('md')} {
        display: flex;
      }
    `,
    poolData: css`
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      ${theme.breakpoints.down('md')} {
        margin: 32px 0 40px;
      }
    `,
    columnsTitle: css`
      width: 100%;
      font-weight: 500;
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 16px;
      color: ${theme.palette.text.secondary};
      ${theme.breakpoints.down('md')} {
        font-size: 14px;
        & > svg{
          width: 18px;
          height: 18px;
        }
      }
    `,
  };
};
