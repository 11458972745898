import type { TransactionReceipt } from 'web3-core';

import { Staking } from 'types/contracts';
import {checkForTokenTransactionError} from "../../../../errors";
import BigNumber from "bignumber.js";
import convertTokensToWei from "../../../../utilities/convertTokensToWei";
import {NetworkState} from "../../../../types";

export interface StakingInput {
  stakingContract: Staking;
  accountAddress: string;
  poolId: number;
  amount: string;
  referralId: number;
  network: NetworkState;
}

export type StakeTokenOutput = TransactionReceipt;

const stakeToken = async ({
                        stakingContract,
                        accountAddress,
                        poolId,
                        referralId,
                        amount,
                        network
                      }: StakingInput): Promise<StakeTokenOutput> =>{
    const resp = await stakingContract.methods.stake(poolId, convertTokensToWei({
        value: new BigNumber(amount),
        tokenId: 'tpy',
        network: network
    }).toString(), referralId).send({ from: accountAddress });

    return checkForTokenTransactionError(resp);
}

export default stakeToken;
