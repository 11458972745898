/** @jsxImportSource @emotion/react */
import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'translation';
import {useParams} from 'react-router';

import EastIcon from '@mui/icons-material/East';
import {useStyles} from './styles';
import {Box, Tooltip, Typography, useTheme} from "@mui/material";
import {CardComponent} from "../../components/Card";
import {pools} from "../../__mocks__/api/pools";
import {ConnectedPool, Pool} from "../../components/Pool";
import {StakeModal} from "../../components/StakeModal";
import ConnectButton from "../../components/Layout/ConnectButton";
import {AuthContext} from "../../context/AuthContext";
import {Button} from "../../components";
import PoolHeader from "./components/Pool";
import {PoolType} from "../../types";
import ReferralLink from "./components/ReferralLink";
import {useGetPriceOf, useGetPoolInfo, useGetUserInfoPerPool, useGetAddressTold} from "../../clients/api";
import {POOLS} from "../../constants/pools";
import convertWeiToTokens from "../../utilities/convertWeiToTokens";
import ReactLoading from "react-loading";
import numberFormat from "../../utilities/numberFormat";
import { useSelector } from 'react-redux';
import {getConfig} from "../../features";

export const StakeUi: React.FC = () => {
    const {id}: never = useParams();
    const {t} = useTranslation();
    const theme = useTheme();
    const {network, chainId} = useSelector(getConfig)
    const scrollToPools = useRef<HTMLInputElement>(null);
    const {account} = React.useContext(AuthContext);
    const styles = useStyles({account: account?.address});
    const [activePools, setActivePools] = useState<PoolType[]>(pools);
    const [engagedPools, setEngagedPools] = useState<PoolType[]>([]);
    const { data: poolsInfo } = useGetPoolInfo(
        { poolIds: POOLS, network : network, chainId: chainId },
        {
            enabled: !!chainId
        }
    );
    const {data: referrerId} = useGetAddressTold(
        {
            accountAddress: account?.address || '',
            chainId: chainId
        },
        {
            enabled: !!account?.address,
        },
    );
    const {data: userInfo, isLoading} = useGetUserInfoPerPool({
        poolIds: POOLS,
        accountAddress: account?.address || '',
        network: network,
        chainId: chainId
    }, {
        enabled: !!account?.address,
    });
    const {data: price} = useGetPriceOf(
        {
            accountAddress: account?.address || '',
            tokenId: 'tpy',
            network: network
        }
    );
    useEffect(() => {
        if (poolsInfo) {
            const activePool: PoolType[] = [];
            if (userInfo) {
                const engagedPool: PoolType[] = [];
                pools.forEach((pool: PoolType) => {
                    if (convertWeiToTokens({valueWei: userInfo.userInfo[pool.poolId].stake, tokenId: 'tpy', network: network}) !== "0") {
                        engagedPool.push({
                            ...pool,
                            total: convertWeiToTokens({
                                valueWei: poolsInfo.poolInfo.res[chainId][pool.poolId].totalStakes,
                                tokenId: 'tpy',
                                network: network
                            }),
                            accountBalance: convertWeiToTokens({
                                valueWei: userInfo.userInfo[pool.poolId].reward,
                                tokenId: 'tpy',
                                network: network
                            }),
                            startDate: userInfo.userInfo[pool.poolId].startDate,
                            endDate: userInfo.userInfo[pool.poolId].endDate
                        })
                    } else {
                        activePool.push({
                            ...pool,
                            total: convertWeiToTokens({
                                valueWei: poolsInfo.poolInfo.res[chainId][pool.poolId].totalStakes,
                                tokenId: 'tpy',
                                network: network
                            })
                        })
                    }
                })
                setEngagedPools(engagedPool)
            } else {
                pools.forEach((pool: PoolType) => {
                    activePool.push({
                        ...pool,
                        total: convertWeiToTokens({
                            valueWei: poolsInfo.poolInfo.res[chainId][pool.poolId].totalStakes,
                            tokenId: 'tpy',
                            network: network
                        })
                    })
                })
            }

            setActivePools(activePool)
        }
    }, [poolsInfo, userInfo, network]);
    const handleScroll = () => {
        scrollToPools?.current?.scrollIntoView({behavior: "smooth"});
    }
    return (
        <>
            <StakeModal price={price} referralId={id || 0} />
            <Box css={styles.cardsWrapper}>
                <Box css={styles.cards}>
                    <Typography css={styles.sectionTitle}>
                        {t('layout.stake.referral.title')}
                    </Typography>
                    <CardComponent variant={'small'}>
                        {(isLoading
                                &&
                                <Box sx={{display: 'flex', justifyContent: 'center', height: '100%', alignItems: 'center'}}>
                                    <ReactLoading type={'spinningBubbles'} color={theme.palette.text.secondary} height={40}
                                                  width={40} />
                                </Box>)
                            ||
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                height: '100%'
                            }}>
                                <Typography css={styles.referralBodyText}>
                                    {account ? (engagedPools.length || Number(referrerId?.userId) ? t('layout.stake.referral.referralLink') : t('layout.stake.referral.referralGenerator')) : t('layout.stake.referral.connectText')}
                                </Typography>
                                <Box sx={{display: 'flex', justifyContent: 'end'}}>
                                    {
                                        account ?
                                            ((engagedPools.length  || Number(referrerId?.userId)) ?
                                                <ReferralLink /> :
                                                <Button onClick={handleScroll} small={true}>
                                                    <EastIcon htmlColor={'#fff'} />
                                                </Button>)
                                            :
                                            <ConnectButton />
                                    }
                                </Box>
                            </Box>}
                    </CardComponent>
                </Box>
                <Box css={styles.cards}>
                    <Typography css={styles.sectionTitle}>
                        {t('layout.stake.myAccount.title')}
                    </Typography>
                    <CardComponent variant={'small'}>
                        <Box>
                            <Box>
                                <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                                    <Typography css={styles.sectionBodyRewards}>
                                        + {
                                        userInfo && (((userInfo?.reward - userInfo?.stakes).toFixed(2)).toString().length > 10
                                            ?
                                            <>
                                                <Tooltip
                                                    title={numberFormat({value: (userInfo?.reward - userInfo?.stakes).toFixed(2)})}
                                                    placement="top" arrow>
                                                    <span>{(numberFormat({value: (userInfo?.reward - userInfo?.stakes)}).toString().slice(0, 10))}...</span>
                                                </Tooltip>
                                            </>
                                            :
                                            numberFormat({value: ((userInfo?.reward - userInfo?.stakes).toFixed(2))}))
                                        || 0
                                    } TPY
                                    </Typography>
                                    <Typography css={styles.sectionBodyRewards}>
                                        + {
                                        userInfo && ((((userInfo?.reward - userInfo?.stakes) * (price?.price || 0)).toFixed(2)).toString().length > 10
                                            ?
                                            <>
                                                <Tooltip
                                                    title={numberFormat({value: ((userInfo?.reward - userInfo?.stakes) * (price?.price || 0)).toFixed(2)})}
                                                    placement="top" arrow>
                                                    <span>{((numberFormat({value: ((userInfo?.reward - userInfo?.stakes) * (price?.price || 0)).toFixed(2)})).toString().slice(0, 10))}...</span>
                                                </Tooltip>
                                            </>
                                            :
                                            numberFormat({value: (((userInfo?.reward - userInfo?.stakes) * (price?.price || 0)).toFixed(2))}))
                                        || 0} USD
                                    </Typography>
                                </Box>
                                <Typography css={styles.sectionBodyTexts}>
                                    {t('layout.stake.myAccount.totalRewards')}
                                </Typography>
                            </Box>
                            <Box sx={{marginTop: '20px'}}>
                                <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                                    <Typography css={styles.sectionBodyValues}>
                                        {numberFormat({value: ((userInfo?.reward)?.toFixed(1)) || 0})} TPY
                                    </Typography>
                                    <Typography css={styles.sectionBodyValues}>
                                        ~ {numberFormat({value: ((userInfo?.reward || 0) * (price?.price || 0)).toFixed(1)})} USD
                                    </Typography>
                                </Box>
                                <Typography css={styles.sectionBodyTexts}>
                                    {t('layout.stake.myAccount.totalStaked')}
                                </Typography>
                            </Box>
                        </Box>
                    </CardComponent>
                </Box>
                <Box css={styles.cards}>
                    <Typography css={styles.sectionTitle}>
                        {t('layout.stake.platform.title')}
                    </Typography>
                    <CardComponent variant={'small'}>
                        <Box>
                            <Box>
                                <Box>
                                    <Typography css={styles.sectionBodyValues}>
                                        ~ {
                                        numberFormat({value: ((price?.price || 0) * (poolsInfo?.poolInfo.totalStakes || 0)).toFixed(2)})
                                    } USD
                                    </Typography>
                                </Box>
                                <Typography css={styles.sectionBodyTexts}>
                                    {t('layout.stake.platform.totalLocked')}
                                </Typography>
                            </Box>
                            <Box sx={{marginTop: '20px'}}>
                                <Box>
                                    <Typography css={styles.sectionBodyValues}>
                                        ~ {numberFormat({value: (poolsInfo?.poolInfo.totalStakes.toFixed(2) || null)})} TPY
                                    </Typography>
                                </Box>
                                <Typography css={styles.sectionBodyTexts}>
                                    {t('layout.stake.platform.totalTPYStaked')}
                                </Typography>
                            </Box>
                        </Box>
                    </CardComponent>
                </Box>
            </Box>
            <Box sx={{marginTop: '24px'}}>
                <CardComponent>
                    <PoolHeader title="engagedPools" />
                    {(isLoading
                            && <Box sx={{display: 'flex', justifyContent: 'center', marginTop: '10px'}}>
                                <ReactLoading type={'spinningBubbles'} color={theme.palette.text.secondary} height={40}
                                              width={40} />
                            </Box>)
                        ||
                        <Box css={styles.pools}>
                            {account && engagedPools.length ? engagedPools.map((item, key) =>
                                <Pool key={key}
                                      poolId={item.poolId}
                                      isEmpty={false}
                                      percent={item.percent}
                                      term={item.term}
                                      startDate={item.endDate}
                                      stake={item.total.toString()}
                                      accountStake={item.accountBalance.toString()}
                                      disable={false}
                                      account={true}
                                      type={'engaged'}
                                      disabled={item.disabled}
                                />
                            ) : <ConnectedPool account={account?.address} scrollTo={handleScroll} />}
                        </Box>
                    }

                </CardComponent>
            </Box>
            <Box sx={{marginTop: '24px'}} ref={scrollToPools}>
                <CardComponent>
                    <PoolHeader title="activePools" />
                    <Box css={styles.pools}>
                        {activePools.map((item, key) =>
                            <Pool key={key}
                                  poolId={item.poolId}
                                  isEmpty={false}
                                  percent={item.percent}
                                  term={item.term}
                                  stake={item.total.toString()}
                                  accountStake={'0'}
                                  disable={false}
                                  account={true}
                                  type={"active"}
                                  disabled={item.disabled}
                            />
                        )}
                    </Box>
                </CardComponent>
            </Box>
        </>
    );
};

const Stake: React.FC = () => (
    <StakeUi />
);

export default Stake;
