import Web3 from 'web3';
import sample from "lodash/sample";

// @todo: we will get the "No 'Access-Control-Allow-Origin' header is present on the requested resource"
// error on the frontend in testnet when the wallet is unconnected, because the bsc official testnet endpoints
// don't support CORS request right now, and we didn't find any working testnet endpoints for
// HTTPProvider or WebSocketProvider neither.
const getWeb3NoAccount = (rpcUrls: string[]) => {
  const rpcUrl = sample(rpcUrls) as string;
  const httpProvider = new Web3.providers.HttpProvider(rpcUrl, {
    timeout: 10000,
  });
  return new Web3(httpProvider);
};

export default getWeb3NoAccount;
