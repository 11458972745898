/** @jsxImportSource @emotion/react */
import React from 'react';
import { useTranslation } from 'translation';
import { truncateAddress } from 'utilities';

import { AuthContext } from 'context/AuthContext';

import { ButtonProps, PrimaryButton } from '../../Button';
import WalletIcon from '../../../assets/img/icons/walletIcon.svg';
import {Typography} from "@mui/material";
import {useStyles} from "./styles";

export interface ConnectButtonProps extends ButtonProps {
  accountAddress?: string;
}

export const ConnectButtonUi: React.FC<ConnectButtonProps> = ({
  accountAddress,
  ...otherProps
}) => {
  const { t } = useTranslation();
  const styles = useStyles();
  return (
    <PrimaryButton {...otherProps}>
      <>
        <img
          src={`${WalletIcon}?fit=crop&auto=format`}
          srcSet={`${WalletIcon}?fit=crop&auto=format&dpr=2 2x`}
          width={14}
          height={14}
          alt="logo"
          loading="lazy"
        />
        <Typography css={styles.text}>
          {!accountAddress ? t('connectButton.title') : truncateAddress(accountAddress)}
        </Typography>
      </>
    </PrimaryButton>
  );
};

export const ConnectButton: React.FC<ButtonProps> = props => {
  const { account, openAuthModal } = React.useContext(AuthContext);
  return (
    <ConnectButtonUi
      accountAddress={account?.address}
      onClick={openAuthModal}
      variant="primary"
      {...props}
    />
  );
};

export default ConnectButton;
