import { MutationObserverOptions, useMutation } from 'react-query';

import FunctionKey from 'constants/functionKey';

import stakeToken, {StakeTokenOutput, StakingInput} from "./index";
import {useStakeContract} from "../../../contracts";
import queryClient from "../../queryClient";
import {POOLS} from "../../../../constants/pools";
import { TPYChainId } from 'types';

const useStakeToken = (
    { accountAddress, chainId }: { accountAddress: string; chainId: TPYChainId },
    options?: MutationObserverOptions<
        StakeTokenOutput,
        Error,
        Omit<StakingInput, 'stakingContract'>
        >,
) => {
  const stakingContract = useStakeContract(chainId);

  return useMutation(
      [FunctionKey.STAKE_TOKEN, `stakeContract ${chainId}` ],
      params =>
          stakeToken({
              stakingContract,
            ...params
          }),
      {
        ...options,
        onSuccess: (...onSuccessParams) => {
            queryClient.invalidateQueries([FunctionKey.GET_USER_INFO, `${POOLS.join(',')} ${accountAddress} ${chainId}`]);
            if (options?.onSuccess) {
            options.onSuccess(...onSuccessParams);
          }
        },
      },
  );
};

export default useStakeToken;
