export enum Subdirectory {
    DASHBOARD = '/',
    STAKE = '/',
    REFERRAL = '/stake/:network/:id',
}

const routeSubdirectories = {
  stake: [Subdirectory.STAKE],
  referral: [Subdirectory.REFERRAL],
  dashboard: [Subdirectory.DASHBOARD],
};

type RouteName = keyof typeof routeSubdirectories;

type Routes = {
  [key in RouteName]: {
    path: string;
    subdirectories: Subdirectory[];
  };
};

export const routes = Object.keys(routeSubdirectories).reduce<Routes>(
  (obj, key) =>
    Object.prototype.hasOwnProperty.call(routeSubdirectories, key)
      ? {
          ...obj,
          [key]: {
            path: routeSubdirectories[key as RouteName].join(''),
            subdirectories: routeSubdirectories[key as RouteName],
          },
        }
      : obj,
  {} as Routes,
);
