import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  return {
    theme,
    container: css`
      margin: ${theme.spacing(0, 10)};
      text-align: center;

      ${theme.breakpoints.down('md')} {
        margin: ${theme.spacing(-2, 4, 0)};
      }
    `,
    walletList: css`
      margin-bottom: ${theme.spacing(8)};
      display: flex;
      align-items: center;
      margin-top: 48px;
      flex-direction: column;
      row-gap: ${theme.spacing(5)};
      & > button{
        max-width: 292px;
      }
      ${theme.breakpoints.down('md')} {
        margin-top: 40px;
        margin-bottom: ${theme.spacing(2)};
        & > button{
          max-width: 280px;
        }
      }
    `,
    subtitle: css`
      font-size: 16px;
      ${theme.breakpoints.down('md')} {
        font-size: 14px;
      }
    `,
    getListItem: ({ isActionable }: { isActionable: boolean }) => css`
      width: 100%;
      display: flex;
      background: ${theme.palette.interactive.primary};
      align-items: center;
      box-shadow: none;
      border: 2px solid transparent;
      border-radius: 12px;
      padding: 8px 24px;
      color: ${theme.palette.text.primary};
      text-align: center;
      ${theme.breakpoints.down('md')} {
        padding: 8px 18px;
      }
      ${isActionable &&
      css`
        cursor: pointer;

        :hover {
          background: ${theme.palette.interactive.hover};
          border: 2px solid transparent;
        }
      `}
    `,
    walletLogo: css`
      margin: ${theme.spacing(0, 'auto', 1)};
      display: block;

      ${theme.breakpoints.down('md')} {
        width: ${theme.spacing(10)};
      }
    `,
    comingSoonText: css`
      color: ${theme.palette.text.secondary};
    `,
    footer: css`
      text-align: center;
      padding: ${theme.spacing(0, 4)};
    `,
    footerLink: css`
      color: ${theme.palette.button.main};

      :hover {
        color: ${theme.palette.button.medium};
      }

      :active {
        color: ${theme.palette.button.dark};
      }
    `,
  };
};
