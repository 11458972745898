import config from 'config';
import {TPYChainId} from "../../../types";
// Prompt the user to add BSC as a network, or switch to BSC if the wallet is on
// a different network
const switchNetwork = async (chainId?: TPYChainId) => {
  if (!window.ethereum) {
    // TODO: send error to Sentry

    console.error("Can't set up the BSC network on wallet because window.ethereum is undefined");
    return false;
  }

  try {
    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [
        {
          chainId: `0x${chainId ? chainId.toString(16) : config.chainId.toString(16)}`,
        },
      ],
    });

    return true;
  } catch (error) {
    const err = error as  { code: number; message: string};
    throw err.message;
  }
};

export default switchNetwork;
