import { css } from '@emotion/react';
import { useTheme } from '@mui/material';

export const useStyles = () => {
  const theme = useTheme();

  return {
    poolHeader: css`
      padding: 0 24px;
      ${theme.breakpoints.down('md')} {
        padding: 0 16px;
      }
    `,
    poolTitle: css`
      font-size: 25px;
      font-weight: 700;
      color: ${theme.palette.text.primary};
      ${theme.breakpoints.down('xl')} {
        font-size: 25px;
      }

      ${theme.breakpoints.down('md')} {
        font-size: 18px;
        margin-bottom: 24px;
      }
    `,
    columns: css`
      width: 100%;
      margin: 24px 0 17px;
      display: flex;
      justify-content: space-between;
      ${theme.breakpoints.down('md')} {
        display: none;
      }
    `,
    columnsTitle: css`
      width: 100%;
      font-weight: 500;
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 13px;
      justify-content: center;
      color: ${theme.palette.text.secondary};
    `,
    columnsPoolTitle: css`
      width: 190px;
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 13px;
      color: ${theme.palette.text.secondary};
    `,
  };
};
