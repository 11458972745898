import * as React from 'react';
import {styled} from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import {getMode, useAppSelector} from 'features';
import {LANGUAGES} from "../../constants/languages";
import MoonIcon from "../../assets/img/icons/moonIcon.svg";
import SunIcon from "../../assets/img/icons/sunIcon.svg";
import {Mode} from "../../types";

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 56,
    height: 30,
    padding: 2,
    '& .MuiSwitch-switchBase': {
        margin: 3,
        padding: 0,
        transform: 'translateX(3)',
        color: theme.palette.interactive.success50,
        fontWeight: 500,
        '&.Mui-checked': {
            color: theme.palette.interactive.success50,
            transform: 'translateX(25px)',
            '& .MuiSwitch-thumb:before': {
                content: "''",
                fontSize: 12,
                fontWeight: 500,
                position: 'absolute',
                width: '100%',
                height: '100%',
                left: '0',
                top: '0',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundImage: `url(${SunIcon})`
            },
            '& + .MuiSwitch-track': {
                opacity: 1,
                background: theme.palette.switchMode.background,
            },
        },
    },
    '& .MuiSwitch-thumb': {
        backgroundColor: '#FCFCFF',
        width: 24,
        height: 24,
        '&:before': {
            content: "''",
            backgroundImage: `url(${MoonIcon})`,
            fontSize: 12,
            fontWeight: 600,
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: '-1px',
            top: '0',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
        },
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        background: theme.palette.switchMode.background,
        borderRadius: 26 / 2,
        '&:before': {
            content: '"Off"',
            position: 'absolute',
            fontSize: 11,
            fontWeight: 500,
            color: theme.palette.switchMode.text,
            top: '50%',
            right: 8,
            transform: 'translateY(-50%)',
            width: 'auto',
            height: 16,
        },
        '&:after': {
            content: '"On"',
            fontSize: 12,
            position: 'absolute',
            fontWeight: 500,
            color: theme.palette.switchMode.text,
            top: '47%',
            left: 5,
            transform: 'translateY(-50%)',
            width: 16,
            height: 16,
        },
    },
}));


const MaterialUILanguageSwitch = styled(Switch)(({ theme }) => ({
    width: 56,
    height: 30,
    padding: 2,
    '& .MuiSwitch-switchBase': {
        margin: 3,
        padding: 0,
        transform: 'translateX(3)',
        fontWeight: 500,
        '&.Mui-checked': {
            transform: 'translateX(25px)',
            '& .MuiSwitch-thumb:before': {
                content: "'中文'",
                fontSize: 12,
                fontWeight: 500,
                position: 'absolute',
                width: '100%',
                height: '100%',
                left: '0',
                top: '3px',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
            },
            '& + .MuiSwitch-track': {
                opacity: 1,
                background: theme.palette.switchLang.background,
            },
        },
    },
    '& .MuiSwitch-thumb': {
        background: theme.palette.switchLang.iconBackground,
        width: 24,
        height: 24,
        '&:before': {
            content: "'EN'",
            fontSize: 12,
            color: theme.palette.switchLang.activeText,
            fontWeight: 600,
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: '3px',
            top: '3px',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
        },
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        background: theme.palette.switchLang.background,
        borderRadius: 26 / 2,
        '&:before': {
            content: '"中文"',
            position: 'absolute',
            fontSize: 11,
            fontWeight: 500,
            color: theme.palette.switchLang.text,
            top: '50%',
            right: 5,
            transform: 'translateY(-50%)',
            width: 'auto',
            height: 16,
        },
        '&:after': {
            content: '"EN"',
            fontSize: 12,
            position: 'absolute',
            fontWeight: 500,
            color: theme.palette.switchLang.text,
            top: '50%',
            left: 5,
            transform: 'translateY(-50%)',
            width: 16,
            height: 16,
        },
    },
}));

export const SwitchComponent = ({ change }: { change: () => void }) => {
    const mode = useAppSelector(getMode);
    return <FormGroup>
        <FormControlLabel
          control={<MaterialUISwitch sx={{ m: 1 }} onChange={change} checked={mode === Mode.LIGHT} />}
          sx={{
              margin: 0,
          }}
          label=""
        />
           </FormGroup>;
};


export const SwitchLanguageComponent = ({ change }: { change: () => void }) => {
    const lang = localStorage.getItem('i18next') || LANGUAGES[0];
    return <FormGroup>
        <FormControlLabel
          control={<MaterialUILanguageSwitch sx={{ m: 1 }} onChange={change} checked={lang === 'zh'} />}
          sx={{
              margin: 0,
          }}
          label=""
        />
           </FormGroup>;
};
