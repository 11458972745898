import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
import DarkBg from '../../assets/img/bg.svg';
import Light from '../../assets/img/bgLight.svg';
import {useSelector} from "react-redux";
import { getMode } from 'features';

export const useStyles = () => {
  const theme = useTheme();
  const mode = useSelector(getMode);

  return {
    main: css`
      background: ${theme.palette.customBackground.default};
    `,
    background: css`
      background-image: url(${mode === 'dark' ? DarkBg : Light});
      background-repeat: no-repeat;
      background-position-y: 0;
      background-size: ${mode === 'dark' ? '100% 112%' : '100% 130%'};
      ${theme.breakpoints.down('lg')} {
        background-image: none;
      }
    `,
    layout: css`
      display: flex;
      flex: 1;
      flex-direction: column;
      min-height: 91vh;
      ${theme.breakpoints.down('lg')} {
        flex-direction: column;
      }
    `,
  };
};
