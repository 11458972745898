/** @jsxImportSource @emotion/react */
import React  from 'react';
import { useTranslation } from 'translation';
import {Container, Box, Typography} from '@mui/material';
import { useStyles } from './styles';
import {Line} from "../../Line";
import SocialMedia from "./components/socialMedia";

const FooterUi: React.FC = () => {
   const styles = useStyles();
    const { t } = useTranslation();

    return (
        <>
            <Container maxWidth={false} css={styles.footer}>
                <Box css={styles.pageLinks}>
                    <Box sx={{display: 'flex', gap: '40px', alignItems: 'center'}}>
                        <a href="https://thrupenny.io/" target={'_blank'}>
                            <Typography css={styles.footerLinks}>
                                {t('footer.projects')}
                            </Typography>
                        </a>
                    </Box>
                    <SocialMedia />
                </Box>
                <Line/>
                <Box css={styles.footerThrupenny}>
                    <Typography css={styles.footerInfo}>
                        &copy; {t('footer.text')}
                    </Typography>
                </Box>
            </Container>
        </>
    );
};

export default FooterUi;
