/** @jsxImportSource @emotion/react */
import Typography from '@mui/material/Typography';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'translation';

import ThrupennyLogo from 'assets/img/thrupennyLogo.svg';
import ThrupennyLogoLight from 'assets/img/thrupennyLogoLight.svg';

import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import {Button, FormControl, Select, SelectChangeEvent} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import {useHistory} from 'react-router';
import {useLocation} from 'react-router-dom';
import ConnectButton from '../ConnectButton';
import {Toolbar} from '../Toolbar';
import {useStyles} from './styles';
import {Mode, NetworkState, TPYChainId} from '../../../types';
import {LANGUAGES} from '../../../constants/languages';
import {changeConfig, changeMode, getMode, useAppDispatch, useAppSelector} from '../../../features';
import {SwitchComponent} from '../../Switch';
import {DrawerComponent} from "../../Drawer";
import SelectNetworkButton from "../SelectNetworkButton"
import switchNetwork from "../../../clients/web3/useAuth/switchNetwork";
import setUpNetwork from "../../../clients/web3/useAuth/setUpNetwork";

const NavbarUi: React.FC = () => {
    const history = useHistory();
    const location = useLocation();
    const {t, i18n} = useTranslation();
    const styles = useStyles();
    const mode = useAppSelector(getMode);
    const pages = [{name: 'stake', link: '/'}];
    const dispatch = useAppDispatch();
    const [language, setLanguage] = useState(localStorage.getItem('i18next') || LANGUAGES[0]);
    const handleNavigateToPage = (page: string | null) => page && history.push(page);

    useEffect(() => {
            const params = location.pathname.split('/');
            if (params[2] && params[3]) {
                const chainId = params[2] as TPYChainId;
                switchNetwork(+params[2] as TPYChainId).then().catch((e) => {
                    const message = e as string;
                    if (message.includes('wallet_addEthereumChain')) {
                        setUpNetwork(+chainId).then(async () => {
                            localStorage.setItem('network', chainId.toString());
                            await dispatch(changeConfig({network: chainId.toString() as NetworkState}));
                        })
                    }
                })
            }
        },
        [location.pathname]
    );
    const handleMode = () => {
        dispatch(changeMode((mode === Mode.LIGHT && Mode.DARK) || Mode.LIGHT));
    };

    const handleChange = (event: SelectChangeEvent) => {
        setLanguage(event.target.value);
        i18n.changeLanguage(event.target.value);
        localStorage.setItem('i18next', event.target.value);
    };
    return (
        <>
            <AppBar position="static" css={styles.appbar}>
                <Toolbar css={styles.toolbar}>
                    <Typography
                        variant="h6"
                        component="a"
                        href="/"
                        sx={{
                            display: 'flex',
                            textDecoration: 'none',

                        }}
                        css={styles.logo}
                    >
                        <img
                            src={`${mode === Mode.LIGHT ? ThrupennyLogoLight : ThrupennyLogo}`}
                            height={25}
                            srcSet={`${mode === Mode.LIGHT ? ThrupennyLogoLight : ThrupennyLogo}?fit=crop&auto=format&dpr=2 2x`}
                            alt="logo"
                            loading="lazy"
                        />
                    </Typography>
                    <Box sx={{
                        flexGrow: 1,
                        display: {xs: 'none', md: 'none', lg: 'flex'},
                        gap: 8,
                        marginLeft: 20,
                        height: '100%',
                    }}
                    >
                        {pages.map((page) => (
                            <Button
                                key={page.name}
                                onClick={() => handleNavigateToPage(page.link)}
                                css={location.pathname === page.link ? styles.activeLink : styles.item}
                            >
                                {t(`header.menuItems.${page.name}`)}
                            </Button>
                        ))}
                        <a
                            href="https://app.thrupenny.io/"
                            target={'_blank'}
                            css={styles.linkHref}
                        >
                            <span>
                            {t('footer.yieldFarming')}
                            </span>
                        </a>
                    </Box>
                    <Box css={styles.connectPart}>
                        <Box sx={{flexGrow: 0, display: {md: 'none', lg: 'flex', xs: 'none'},}}>
                            <FormControl sx={{m: 1, minWidth: 50}} size="small">
                                <SelectNetworkButton />
                            </FormControl>
                        </Box>
                        <Box sx={{flexGrow: 0}}>
                            <ConnectButton />
                        </Box>
                        <Box sx={{flexGrow: 0, display: {md: 'none', lg: 'flex', xs: 'none'},}}>
                            <FormControl sx={{m: 1, minWidth: 50}} size="small">
                                <Select
                                    value={language}
                                    onChange={handleChange}
                                    css={styles.select}
                                >
                                    {LANGUAGES.map(lang => <MenuItem
                                        key={lang}
                                        value={lang}
                                    >{t(`languages.${lang}`)}
                                    </MenuItem>)}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box sx={{flexGrow: 0, display: {md: 'none', lg: 'flex', xs: 'none'},}}>
                            <SwitchComponent change={handleMode} />
                        </Box>
                        <Box sx={{flexGrow: 1, display: {md: 'flex', lg: 'none'}}}>
                            <DrawerComponent />
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>
        </>
    );
};

export default NavbarUi;
