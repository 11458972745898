/** @jsxImportSource @emotion/react */
import React  from 'react';
import TelegramIcon from '@mui/icons-material/Telegram';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import {ReactComponent as MediumIcon} from '../../../../../assets/img/icons/medium.svg';
import {ReactComponent as GitBookIcon} from '../../../../../assets/img/icons/gitBookIcon.svg';
import {Box, useTheme} from '@mui/material';
import {useStyles} from "./styles";

const SocialMedia: React.FC = () => {
    const styles = useStyles();
    const theme = useTheme();
    return (
        <>
            <Box css={styles.socialMedia}>
                <a href="https://thrupenny.gitbook.io/whitepaper/background/introduction" target={'_blank'}>
                    <GitBookIcon css={styles.icons}/>
                </a>
                <a href="https://www.linkedin.com/company/thrupenny/" target={'_blank'}>
                    <LinkedInIcon css={styles.icons}/>
                </a>
                <a href="https://www.youtube.com/channel/UCYINM6pYtzTsQ5iAdhSnncg?app=desktop" target={'_blank'}>
                    <YouTubeIcon css={styles.icons}/>
                </a>
                <a href="https://medium.com/@thrupennyprotocol" target={'_blank'}>
                    <MediumIcon css={styles.icons}/>
                </a>
                <a href="https://t.me/thrupenny" target={'_blank'}>
                    <TelegramIcon htmlColor={theme.palette.text.primary}/>
                </a>
                <a href="https://twitter.com/Thrupennydefi?s=20&t=1AzVqIu0BuAwcK7lrO-3tA" target={'_blank'}>
                    <TwitterIcon htmlColor={theme.palette.text.primary}/>
                </a>
            </Box>
        </>
    );
};

export default SocialMedia;
