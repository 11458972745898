import config from 'config';

import TOKEN_ADDRESSES from './contracts/addresses/tokens.json';
import {NetworkState} from "../types";

export const TOKENS = config.isOnTestnet
    ? {
        tpy: {
            [NetworkState.ARBITRUM]: {
                id: 'tpy',
                symbol: 'TPY',
                decimals: 8,
                address: TOKEN_ADDRESSES.tpy["arbitrum"][97],
                coingeckoId: 'thrupenny'
            },
            [NetworkState.ETH]: {
                id: 'tpy',
                symbol: 'TPY',
                decimals: 8,
                address: TOKEN_ADDRESSES.tpy["etherium"][97],
                coingeckoId: 'thrupenny'
            }
        },
    }
    : {
        tpy: {
            [NetworkState.ARBITRUM]: {
                id: 'tpy',
                symbol: 'TPY',
                decimals: 8,
                address: TOKEN_ADDRESSES.tpy["arbitrum"][56],
                coingeckoId: 'thrupenny'
            },
            [NetworkState.ETH]: {
                id: 'tpy',
                symbol: 'TPY',
                decimals: 8,
                address: TOKEN_ADDRESSES.tpy["etherium"][56],
                coingeckoId: 'thrupenny'
            },
        },
    };
