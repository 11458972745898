enum FunctionKey {
  // Queries
  GET_TOKEN_PRICE = 'GET_TOKEN_PRICE',
  GET_BLOCK_NUMBER = 'GET_BLOCK_NUMBER',
  GET_TOKEN_ALLOWANCE = 'GET_TOKEN_ALLOWANCE',
  GET_BALANCE_OF = 'GET_BALANCE_OF',
  GET_TRANSACTIONS = 'GET_TRANSACTIONS',
  GET_POOL_INFO = 'GET_POOL_INFO',
  GET_USER_INFO = 'GET_USER_INFO',
  GET_USER_ID = 'GET_USER_ID',

  // Mutations
  APPROVE_TOKEN = 'APPROVE_TOKEN',
  STAKE_TOKEN = 'STAKE_TOKEN',
  WITHDRAW_TOKEN = 'WITHDRAW_TOKEN',
}

export default FunctionKey;
